<template>
    <div class="card">
        <el-image class="card-item" :src="require('@/assets/xihe_logo.png')"></el-image>
        <h1 class="card-item">曦禾考级系统</h1>
    </div>
</template>

<script>
    import user from "@/models/user"
    import interaction from "@/utils/interaction"
    import SystemPara from '@/data/SystemPara'

    export default {
        name: 'main',
        props: {
            msg: String
        },
        created() {
            user.userIsLogin();
        },
        mounted() {
            document.querySelector("body").setAttribute("style", "background: linear-gradient(to right, rgb(255, 239, 186), rgb(255, 255, 255))");
        },
        beforeRouteLeave() {
            document.querySelector("body").removeAttribute("style");
        }
    }
</script>

<style scoped>
.card {
    width: 400px;
    position: absolute;
    top: 30%;
    left: 0%;
    right: 0%;
    margin: auto;

    justify-content: center;
    align-items: center;
    text-align: center;
}

.card-item {
    margin-bottom: 10px;
}
</style>
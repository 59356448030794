
import * as XLSX from 'xlsx/xlsx.mjs'
import interaction from '@/utils/interaction'

function contestantUploadExampleXlsx() {
    const data = XLSX.utils.aoa_to_sheet([
        ["身份证", "姓名", "考核等级"]
    ])
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, data, 'data')
    XLSX.writeFile(wb, '批量导入选手表.xlsx')
}

function readContestantUploadXlsx(file, readDataCallBack) {
    if (!file) {
        interaction.showErrorMessage("file error");
        return
    }
    if (!/\.(xlsx)$/.test(file.name.toLowerCase())) {
        interaction.showErrorMessage("请上传Excel文件！");
        return
    }

    const fileReader = new FileReader()

    fileReader.onload = (ev) => {
        try {
            const data = ev.target.result
            const workbook = XLSX.read(data, {
                type: 'binary'
            })

            const sheet = workbook.SheetNames[0]
            const xlsxData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
            
            readDataCallBack(xlsxData);
            return true;

        } catch (e) {
            console.log(e);
            interaction.showErrorMessage("文件读取出现错误！");
            return false;
        }
    }

    fileReader.readAsBinaryString(file)
}


//原始数据
function getContestantRawData(matches) {
    var data = [];

    var titleData = ["身份证", "姓名"];
    for(var i = 0 ; i < matches.scoreRatio.length ; i ++) {
        titleData.push(matches.scoreRatio[i].scoreTitle);
    }
    data.push(titleData);

    for(var i = 0 ; i < matches.contestant.length ; i ++) {
        var rowData = [];
        rowData.push(matches.contestant[i].studentId);
        rowData.push(matches.contestant[i].studentName);
        for(var j = 0 ; j < matches.scoreRatio.length ; j ++) {
            rowData.push(matches.contestant[i].contestantScore[j].score);
        }
        data.push(rowData);
        rowData = [];
    }

    const sheetData = XLSX.utils.aoa_to_sheet(data)
    const wb = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(wb, sheetData, 'data')
    XLSX.writeFile(wb, matches.name + "-原始成绩.xlsx");
}

export default {
    contestantUploadExampleXlsx,
    readContestantUploadXlsx,
    getContestantRawData,
}
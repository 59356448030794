
import SystemPara from "@/data/SystemPara";
import http from "@/utils/http";
import errorCode from "./errorCode";

const rootUrl = "/FileImage/"

// const serverUrl = "/xiheServer/";
const serverUrl = "https://edu.xihetiyu.com/API/"


function getMatchesQRCodeUrl(matchesId) {
    return `${serverUrl}Matches/getMatchesQRCode?userToken=${SystemPara.getUserToken()}&matchesId=${matchesId}`;
}

function getStudentImagePath(studentId, studentName) {
    return `${serverUrl}${rootUrl}getStudentImage?userToken=${SystemPara.getUserToken()}&studentId=${studentId}&studentName=${studentName}`;
}

function getStudentImagePathNeedRefersh(studentId, studentName) {
    return `${serverUrl}${rootUrl}getStudentImage?userToken=${SystemPara.getUserToken()}&studentId=${studentId}&studentName=${studentName}&time=${new Date()}`;
}

function getUserImagePath(userId) {
    return `${serverUrl}${rootUrl}getUserImage?userToken=${SystemPara.getUserToken()}&userId=${userId}`;
}

function getGymsImagePath(gymsId) {
    return `${serverUrl}${rootUrl}getGymsImage?userToken=${SystemPara.getUserToken()}&gymsId=${gymsId}`;
}

function uploadStudentImage(file, studentId, studentName, success, fail) {
    var data = new FormData();
    data.append('userToken', SystemPara.getUserToken());
    data.append('file', file);
    data.append('studentId', studentId);
    data.append('studentName', studentName);

    http.postServer(
        rootUrl + "uploadStudentImage",
        data,
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function uploadUserImage(file, userId, success, fail) {
    var data = new FormData();
    data.append('userToken', SystemPara.getUserToken());
    data.append('file', file);
    data.append('userId', userId);

    http.postServer(
        rootUrl + "uploadUserImage",
        data,
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function uploadGymsImage(file, gymsId, success, fail) {
    var data = new FormData();
    data.append('userToken', SystemPara.getUserToken());
    data.append('file', file);
    data.append('gymsId', gymsId);

    http.postServer(
        rootUrl + "uploadGymsImage",
        data,
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function downloadImagesFileByExamResult(matchesId, fileName, examResult, fail, processItem) {
    fileName += ".zip";


    http.postServer(
        rootUrl + "getStudentImageZipByMatches",
        {
            userToken: SystemPara.getUserToken(),
            matchesId: matchesId,
            examResult: examResult,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            http.postServerByDownload(
                result.data,
                fileName,
                (res) => {
                    fail(errorCode.getErrorCodeByText())
                },
                processItem,
            )
        },
        (res) => {
            fail(errorCode.getErrorCodeByText());
        }
    )
}

export default {
    getMatchesQRCodeUrl,
    getStudentImagePath,
    getStudentImagePathNeedRefersh,
    getUserImagePath,
    getGymsImagePath,
    uploadStudentImage,
    uploadUserImage,
    uploadGymsImage,
    downloadImagesFileByExamResult: downloadImagesFileByExamResult,
}
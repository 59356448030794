<template>
    <div class="parentView">
        <div class="title">
            <h1>考级列表</h1>
        </div>
        <el-table :data="matchesList" stripe style="width: 100%;" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}">
            <el-table-column prop="name" label="考级名称" />
            <el-table-column prop="time" label="考级时间" />
            <el-table-column prop="state" label="考级状态" />
            <el-table-column label="详情">
                <template #default="scope">
                    <el-button type="primary" size="large" @click="showMatches(scope.$index)">查看详情</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import matchesModel from '@/models/matches'
    import interaction from '@/utils/interaction'
    import dataJudgment from '@/utils/dataJudgment'

    export default {
        name: 'list',
        props: {
            msg: String
        },
        data() {
            return {
                matchesList: undefined,
            }
        },
        methods: {
            updateMatchesList(matchesList) {
                this.matchesList = matchesList;
                this.matchesList.sort((a, b) => {
                    return a.time - b.time;
                })
                for(var i = 0 ; i < this.matchesList.length ; i ++) {
                    this.matchesList[i].state = matchesModel.getMatchesState(this.matchesList[i].state);
                    this.matchesList[i].time = dataJudgment.formatDate(this.matchesList[i].time);
                }
            },
            showMatches(index) {
                this.$router.push({
                    name: 'matchesControlShow',
                    params: {
                        matchesId: this.matchesList[index].matchesId
                    }
                })
            }
        },
        mounted(){
            matchesModel.showMatchesList(
                (res) => {
                    this.updateMatchesList(res);
                },
                (res) => {
                    interaction.showErrorMessage(res);
                }
            )
        }
    }
</script>

<style scoped>
    .parentView {
        margin-left: 10%;
        margin-right: 10%;
    }

    .title {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .information {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

</style>
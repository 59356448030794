
import http from "@/utils/http"
import SystemPara from "@/data/SystemPara"
import errorCode from "./errorCode"

var rootUrl = "/MatchesRatio/"

function createMatchesRatio(name, ratioListData, success, fail) {
    http.postServer(
        rootUrl + "createMatchesRatio",
        {
            userToken: SystemPara.getUserToken(),
            name: name,
            ratioListData: ratioListData,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function deleteMatchesRatioByRatioId(ratioId, success, fail) {
    http.postServer(
        rootUrl + "deleteMatchesRatioByRatioId",
        {
            userToken: SystemPara.getUserToken(),
            ratioId: ratioId,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function updateMatchesRatioByRatioId(ratioId, ratioListData, success, fail) {
    http.postServer(
        rootUrl + "updateMatchesRatioByRatioId",
        {
            userToken: SystemPara.getUserToken(),
            ratioId: ratioId,
            ratioListData: ratioListData,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function selectMatchesRatioByRatioId(ratioId, success, fail) {
    http.postServer(
        rootUrl + "selectMatchesRatioByRatioId",
        {
            userToken: SystemPara.getUserToken(),
            ratioId: ratioId,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success(result.data)
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function selectMatchesRatioByUserId(success, fail) {
    http.postServer(
        rootUrl + "selectMatchesRatioByUserId",
        {
            userToken: SystemPara.getUserToken(),
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success(JSON.parse(result.data))
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

export default {
    createMatchesRatio,
    deleteMatchesRatioByRatioId,
    updateMatchesRatioByRatioId,
    selectMatchesRatioByRatioId,
    selectMatchesRatioByUserId,
};
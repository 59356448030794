<template>
    <div v-if="matches != undefined" class="parentViewByScreen">
        <div class="title titleFontSizeByScreen">
            <p>{{ matches.name }}</p>
        </div>
        <div class="information">
            <h3>考级考官：{{ matches.examiner.examinerName }}</h3>
            <h3>当前考核等级：{{ currentNextGradeText }}</h3>
        </div>

        <el-table :data="matches.contestant" stripe style="width: 100%;" :row-style="{height:0+'px'}" :header-cell-style="{'text-align': 'center', 'padding': '0px'}" :cell-style="{'text-align': 'center', 'padding': '0px'}">
            <el-table-column label="图片" width="70px">
                <template #default="scope">
                    <img :src="matches.contestant[scope.$index].imageUrl" style="width: 50px; height: 75px" />
                </template>
            </el-table-column>
            <el-table-column prop="studentName" label="姓名" min-width="150px">
                <template #default="props">
                    <p class="studentNameFont">{{ props.row.studentName }}</p>
                </template>
            </el-table-column>
            <el-table-column :label="matches.scoreRatio.ratioList[matches.currentMatchesRatioPage].ratioTitle">
                <el-table-column v-for="(item, index) in matches.scoreRatio.ratioList[matches.currentMatchesRatioPage].ratioSubItem" :key="item" :label="item.ratioTitle + '(' + item.ratioScore + ')'">
                    <template #default="scope">
                        <h2>{{ matches.contestant[scope.$index].contestantScore[matches.currentMatchesRatioPage].subScore[index].score }}</h2>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column v-if="settingsItem.scoreAllisShow" :label="'总分数(' + matches.ratioScoreAll + ')'">
                <template #default="scope">
                    <h2>{{ matches.contestant[scope.$index].scoreAll }}</h2>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import matchesModel from '@/models/matches'
    import fileImageModel from '@/models/fileImage'

    export default {
        name: 'screen',
        props: {
            msg: String
        },
        data() {
            return {
                matches: undefined,
                currentNextGradeText: undefined,

                scoreRatioByScoreAll: undefined,
                contestantScoreByScoreAll: undefined,

                settingsItem: undefined,
            }
        },
        mounted() {
            window.addEventListener('storage', event => {
                if(event.key == "screenData") {
                    this.updateMatchesData(event.newValue);
                }
            })
            this.updateMatchesData(localStorage.getItem("screenData") || undefined);
        },
        methods: {
            updateMatchesData(data) {
                if(data == undefined)
                    return;

                this.matches = JSON.parse(data);
                
                if(this.matches.contestant == undefined) {
                    this.currentNextGradeText = "";
                    return;
                }

                this.settingsItem = this.matches.settingsItem;
                this.currentNextGradeText = matchesModel.getGradeText(this.matches, this.matches.contestant[0].nextGrade);

                //图片设置
                for(var i = 0 ; i < this.matches.contestant.length ; i ++) {
                        this.matches.contestant[i].imageUrl = fileImageModel.getStudentImagePath(this.matches.contestant[i].studentId, this.matches.contestant[i].studentName);
                }

                //获取所有考核项分值总和
                this.matches.ratioScoreAll = 0;
                for(var ratioItem of this.matches.scoreRatio.ratioList) {
                    this.matches.ratioScoreAll += ratioItem.ratioScore;
                }

                //只要有一个子项没有打分，所有人的总成绩均不显示
                var allScoreIsExists = true;

                var currentMatchesRatioPage = this.matches.currentMatchesRatioPage;
                var subItemAmount =  this.matches.contestant[0].contestantScore[currentMatchesRatioPage].subScore.length;

                //一次循环所有选手的某一子项
                for(var i = 0 ; i < subItemAmount ; i ++) {
                    var scoreColIsValue = true;

                    for(var contestantItem of this.matches.contestant) {
                        var subScoreItem = contestantItem.contestantScore[currentMatchesRatioPage].subScore[i];

                        //如果弃考的话
                        if(contestantItem.isWaiver) {
                            subScoreItem.score = "弃考";
                            continue;
                        }

                        //subScore判断,修改子项显示，为0置空
                        if(subScoreItem.score == 0 || subScoreItem.score == "0") {
                            subScoreItem.score = "";
                            scoreColIsValue = false;
                            continue;
                        }
                        subScoreItem.score = matchesModel.getMatchesContestantScoreText(
                            this.matches.scoreMode,
                            subScoreItem.score
                        );
                    }

                    //若有一个选手某一考核子项为空，该项所有选手均为空
                    if(!scoreColIsValue) {
                        for(var contestantItem of this.matches.contestant) {
                            var subScoreItem = contestantItem.contestantScore[currentMatchesRatioPage].subScore[i];
                            subScoreItem.score = "";
                        }
                    }
                }

                //只有所有人的考核子项成绩都有了，才显示总成绩
                for(var contestantItem of this.matches.contestant) {
                    for(var contestantScoreItem of contestantItem.contestantScore) {
                        for(var subScoreItem of contestantScoreItem.subScore) {
                            if(subScoreItem.score == 0 || subScoreItem.score == "0") {
                                allScoreIsExists = false;
                                continue;
                            }
                        }
                    }
                }

                
                for(var item of this.matches.contestant) {
                    //只要有一个子项没有打分，所有人的总成绩均不显示
                    if(!allScoreIsExists) {
                        item.scoreAll = "";
                        continue;
                    }
                    if(item.scoreAll == 0 || item.scoreAll == "0") {
                        item.scoreAll = "";
                        continue;
                    }
                    item.scoreAll = matchesModel.getMatchesContestantScoreText(
                        this.matches.scoreMode,
                        item.scoreAll
                    );
                }
            }
        }
    }
</script>

<style src="@/css/view.css" scoped></style>
<style src="@/css/information.css" scoped></style>
<style scoped>
.studentNameFont {
    font-size: 30px;
    font-weight: bold;
}
.information {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
</style>
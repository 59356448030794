
import SystemPara from "@/data/SystemPara";
import axios from "axios";

const fileUrl = "/File/"

function downloadProgress(progressEvent, processItem) {
    var number = Math.floor(progressEvent.loaded / progressEvent.total * 100);
    if(processItem != undefined) {
        if(number >= 100)
            number = 99;
        processItem.processNumber = number;
    }
}

function processItemComplete(processItem) {
    if(processItem != undefined) {
        processItem.processNumber = 100;
        processItem.isShow = false;
    }
}

function getServer(url, success, fail) {
    axios.get(url)
    .then((res) => {
        success(res);
    })
    .catch((res) => {
        fail(res);
    })
}

function postServer(url, data, success, fail) {
    axios.post(url, data)
    .then((res) => {
        success(res);
    })
    .catch((res) => {
        fail(res);
    })
}

function getServerByDownload(fileToken, fileName, fail, processItem = undefined) {
    axios.get(`${fileUrl}downloadFile?userToken=${SystemPara.getUserToken()}&fileToken=${fileToken}`, {
        responseType:'blob',
        onDownloadProgress(progressEvent) {
            downloadProgress(progressEvent, processItem);
        }
    })
    .then((res) => {
        console.log(res);
        let url = window.URL.createObjectURL(new Blob([res.data]))
        let link = window.document.createElement('a')
        link.style.display = 'none'
        link.href = url
        
        link.setAttribute('download', fileName)
        document.body.appendChild(link)

        link.addEventListener('click', function(res) {
            processItemComplete(processItem);
        })

        link.click()
    })
    .catch((res) => {
        fail(res);
    })
}

function postServerByDownload(fileToken, fileName, fail, processItem = undefined) {
    axios.post(
        `${fileUrl}downloadFile`, 
        {
            userToken: SystemPara.getUserToken(),
            fileToken: fileToken
        },
        {
        responseType:'blob',
        onDownloadProgress(progressEvent) {
            downloadProgress(progressEvent, processItem);
        }
    })
    .then((res) => {
        // var fileName = res.headers["content-disposition"].split(';')[1].split('=')[1];
        // fileName = decodeURIComponent(fileName)
        // console.log(fileName);

        let url = window.URL.createObjectURL(new Blob([res.data]))
        let link = window.document.createElement('a')
        link.style.display = 'none'
        link.href = url
        
        link.setAttribute('download', fileName)
        document.body.appendChild(link)

        link.addEventListener('click', function(res) {
            processItemComplete(processItem);
        })

        link.click()
    })
    .catch((res) => { 
        fail(res);
    })
}

export default {
    getServer,
    postServer,
    getServerByDownload,
    postServerByDownload,
}

import localData from "@/utils/localData"


function getUserToken() {
    return localData.getUserToken() || undefined;
}

function setUserToken(userToken) {
    localData.setUserToken(userToken);
}

export default {
    getUserToken,
    setUserToken,
}
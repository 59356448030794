import SystemPara from "@/data/SystemPara";
import http from "@/utils/http";
import errorCode from "./errorCode";

const rootUrl = "/FileXlsx/"

function getGradeApplicationXlsxByExamResult(matchesId, matchesName, examResult, fail, processItem = undefined) {
    http.postServer(
        `${rootUrl}getGradeApplicationXlsx`,
        {
            userToken: SystemPara.getUserToken(),
            matchesId: matchesId,
            examResult: examResult,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            http.postServerByDownload(
                result.data,
                `级位申办表(${matchesName}).xlsx`,
                () => {
                    fail(errorCode.getErrorCodeByText())
                },
                processItem
            );
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
        }
    );
}

function getContestantListXlsx(matchesId, matchesName, fail, processItem = undefined) {
    http.postServer(
        `${rootUrl}getContestantListXlsx`,
        {
            userToken: SystemPara.getUserToken(),
            matchesId: matchesId,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            http.postServerByDownload(
                result.data,
                `选手考核表(${matchesName}).xlsx`,
                () => {
                    fail(errorCode.getErrorCodeByText())
                },
                processItem
            );
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
        }
    );
}

function getContestantInformationXlsx(matchesId, matchesName, fail, processItem = undefined) {
    http.postServer(
        `${rootUrl}getContestantInformation`,
        {
            userToken: SystemPara.getUserToken(),
            matchesId: matchesId,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            http.postServerByDownload(
                result.data,
                `选手基本信息表(${matchesName}).xlsx`,
                () => {
                    fail(errorCode.getErrorCodeByText())
                },
                processItem
            );
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
        }
    );
}

function getContestantIndexXlsx(matchesId, matchesName, fail, processItem = undefined) {
    http.postServer(
        `${rootUrl}getContestantIndex`,
        {
            userToken: SystemPara.getUserToken(),
            matchesId: matchesId,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            http.postServerByDownload(
                result.data,
                `检录表(${matchesName}).xlsx`,
                () => {
                    fail(errorCode.getErrorCodeByText())
                },
                processItem
            );
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
        }
    );
}

export default {
    getGradeApplicationXlsxByExamResult,
    getContestantListXlsx,
    getContestantInformationXlsx,
    getContestantIndexXlsx
}

import http from "@/utils/http"
import SystemPara from "@/data/SystemPara"
import errorCode from "./errorCode"

var rootUrl = "/Matches/"

var MatchesState = {
    //EnrollState
    "0": "报名状态",
    //WaitToStartState
    "1": "等待开始",
    //GoingOnState
    "2": "正在考级",
    //ScoreProcessingState
    "3": "成绩处理",
    //FinishState,
    "4": "结束",
}

function getMatchesState(state) {
    return MatchesState[state]
}

function getMatchesStateList() {
    var list = []
    for(var key in MatchesState) {
        list.push(MatchesState[key])
    }
    return list
}

var MatchesScoreMode = {
    "0": "通过/不通过模式",
    "1": "A/B/C/D模式",
    "2": "分数填写模式",
}

function getMatchesScoreMode(mode) {
    return MatchesScoreMode[mode]
}

function getMatchesScoreModeList() {
    var list = []
    for(var key in MatchesScoreMode) {
        list.push(MatchesScoreMode[key])
    }
    return list
}

var MatchesContestantScoreText = {
    "0": "0",
    "1": "通过",
    "2": "不通过",
    "3": "A+",
    "4": "A",
    "5": "A-",
    "6": "B+",
    "7": "B",
    "8": "B-",
    "9": "C+",
    "10": "C",
    "11": "D",
}

/**
 * 获取所有字母
 */
function getMatchesContestantScoreTextListByWordMode() {
    var list = [];
    for(var key in MatchesContestantScoreText) {
        list.push(MatchesContestantScoreText[key])
    }
    list.splice(0, 3);
    return list;
}

function getMatchesContestantScoreText(mode, score) {
    mode = parseInt(mode);
    if(mode < 0 || mode >= 2) {
        return score;
    }
    return MatchesContestantScoreText[score]
}

var MatchesExamResultMode = {
    "0": "暂未判定",
    "1": "通过",
    "2": "不通过",
}

function getMatchesExamResultMode(mode) {
    return MatchesExamResultMode[mode]
}

const gradeTextList = [
    "请选择", "白带十级", "白黄带九级", "黄带八级", "黄绿带七级", "绿带六级", 
    "绿蓝带五级", "蓝带四级", "蓝红带三级", "红带二级", "红黑带一级","黑带一段", 
    "黑带二段", "黑带三段", "黑带四段", "黑带五段", "黑带六段", "黑带七段", "黑带八段", "黑带九段"
]

function getGradeText(matches, grade) {
    grade -= 1;
    if(grade < 0) {
        return "";
    }

    return matches.gradeGroup[grade];
}

function getGradeTextList(matches, firstItem = undefined) {
    var gradeGroup = Array.from(matches.gradeGroup);
    if(firstItem != undefined) {
        gradeGroup.unshift(firstItem);
    }
    return gradeGroup;
}


/**
 * 调转顺序 10 -> 1 改为 1-> 10
 * @param {*} nextGrade 
 * @returns 
 */
function changeGradeNumber(nextGrade) {
    nextGrade = parseInt(nextGrade);
    if(nextGrade >= 1 && nextGrade <= 10) {
        return 11 - nextGrade;
    }
}

/**
 * 修改选手排序，按照级位/段位，批次，顺序修改
 * @param {*} contestant 
 * @returns 
 */
function matchesContestantSortByBatchAndIndex(contestant) {
    contestant = contestant.sort(
        (a, b) => {
            return a.nextGrade - b.nextGrade;
        }
    )
    return contestant.sort(
        (a, b) => {
            var batch = a.contestantBatch - b.contestantBatch;
            var index = a.contestantIndex - b.contestantIndex;

            if(batch != 0) {
                return batch;
            }else {
                return index;
            }
        }
    )
}

function matchesContestantSortByNextGrade(contestant) {
    return contestant.sort(
        (a, b) => {
            var nextGrade = a.nextGrade - b.nextGrade;
            var batch = a.contestantBatch - b.contestantBatch;
            var index = a.contestantIndex - b.contestantIndex;

            if(nextGrade != 0) {
                return nextGrade;
            }

            if(batch != 0) {
                return batch;
            }

            return index;
        }
    )
}


function showMatchesList(success, fail) {
    http.postServer(
        rootUrl + "selectMatchesListByUserToken",
        {
            userToken: SystemPara.getUserToken()
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success(JSON.parse(result.data))
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
  }
  
function showMatches(matchesId, success, fail) {
    http.postServer(
        rootUrl + "selectMatchesByMatchesId",
        {
            userToken: SystemPara.getUserToken(),
            matchesId: matchesId,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success(result.data)
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function changeMatchesExam(matchesId, contestant, examinerIndex, success, fail) {
    http.postServer(
        rootUrl + "changeMatchesContestantExam",
        {
            userToken: SystemPara.getUserToken(),
            matchesId: matchesId,
            contestant: JSON.stringify(contestant),
            examinerIndex: examinerIndex,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function changeMatchesEnrollState(matchesId, success, fail) {
    changeMatchesState(matchesId, 0, success, fail)
}

function changeMatchesWaitToStartState(matchesId, success, fail) {
    changeMatchesState(matchesId, 1, success, fail)
}

function changeMatchesGoingOnStateState(matchesId, success, fail) {
    changeMatchesState(matchesId, 2, success, fail)
}

function changeMatchesScoreProcessingState(matchesId, success, fail) {
    changeMatchesState(matchesId, 3, success, fail)
}

function changeMatchesState(matchesId, state, success, fail) {
    http.postServer(
        rootUrl + "changeMatchesState",
        {
            userToken: SystemPara.getUserToken(),
            matchesId: matchesId,
            state: state,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function deleteMatches(matchesId, success, fail) {
    http.postServer(
        rootUrl + "deleteMatches",
        {
            userToken: SystemPara.getUserToken(),
            matchesId: matchesId,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function createMatches(name, scoreRatio, examiner, time, place, scoreMode, success, fail) {
    http.postServer(
        rootUrl + "createMatches",
        {
            userToken: SystemPara.getUserToken(),
            name: name,
            scoreRatio: scoreRatio,
            examiner: examiner,
            time, time,
            place: place,
            scoreMode: scoreMode,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function saveMatchesExamResult(matchesId, contestant, success, fail) {
    http.postServer(
        rootUrl + "changeMatchesExamResult",
        {
            userToken: SystemPara.getUserToken(),
            matchesId: matchesId,
            dataJson: contestant,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function addMatchesContestants(matchesId, contestants, success, fail) {
    http.postServer(
        rootUrl + "addMatchesContestants",
        {
            userToken: SystemPara.getUserToken(),
            matchesId: matchesId,
            contestants: contestants,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function deleteMatchesContestant(matchesId, studentId, nextGrade, success, fail) {
    http.postServer(
        rootUrl + "deleteMatchesContestant",
        {
            userToken: SystemPara.getUserToken(),
            matchesId: matchesId,
            studentId: studentId,
            nextGrade: nextGrade,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function changeMatchesContestantNextGrade(matchesId, studentId, nextGrade, newNextGrade, success, fail) {
    http.postServer(
        rootUrl + "changeMatchesContestantNextGrade",
        {
            userToken: SystemPara.getUserToken(),
            matchesId: matchesId,
            studentId: studentId,
            nextGrade: nextGrade,
            newNextGrade: newNextGrade,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function changeContestantBatchAndIndex(matchesId, contestant, success, fail) {
    http.postServer(
        rootUrl + "changeContestantBatchAndIndex",
        {
            userToken: SystemPara.getUserToken(),
            matchesId: matchesId,
            contestant: contestant,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

export default {
    getMatchesState,
    getMatchesStateList,
    getMatchesScoreMode,
    getMatchesScoreModeList,
    getMatchesContestantScoreTextListByWordMode,
    getMatchesContestantScoreText,
    getMatchesExamResultMode,
    getGradeText,
    getGradeTextList,
    changeGradeNumber,
    matchesContestantSortByBatchAndIndex,
    matchesContestantSortByNextGrade,

    showMatchesList,
    showMatches,
    changeMatchesExam,
    changeMatchesEnrollState,
    changeMatchesWaitToStartState,
    changeMatchesGoingOnStateState,
    changeMatchesScoreProcessingState,
    deleteMatches,
    createMatches,
    saveMatchesExamResult,
    addMatchesContestants,
    deleteMatchesContestant,
    changeMatchesContestantNextGrade,
    changeContestantBatchAndIndex,
}
<template>
  <div v-if="matches != undefined">
    <div class="parentViewByScreen">
      <div class="title titleFontSizeByScreen">
        <p>{{ matches.name }}</p>
      </div>
      <div class="spaceBetweenList">
        <h3>考级考官：{{ matches.examiner.examinerNameList[examinerIndex] }}</h3>
        <el-select v-model="currentMatchesRatioPage">
          <el-option v-for="(item, index) in currentMatchesRatioPageList" :key="item.key" :label="item" :value="index" @click.native="changeScreenData()" />
        </el-select>
        <h3>当前考核等级：{{ currentNextGradeText }}</h3>
      </div>
    </div>

    <el-table :data="matches.contestant" stripe style="width: 100%;" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}">
      <el-table-column label="照片" min-width="80px" width="80px">
        <template #default="props">
          <img v-if='props.$index < matches.contestant.length - 1' class="image" :src="props.row.image" />
        </template>
      </el-table-column>
      <el-table-column label="姓名" min-width="150px" width="150px">
        <template #default="props">
          
          <div v-if='props.$index < matches.contestant.length - 1'>
            <el-button class="text" type="primary" size="large" text @click="changeContestantByOneContestantDialog(props.$index)">{{ props.row.studentName }}</el-button>
          </div>
          <div v-else>
            <p class="text">{{ props.row.studentName }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="matches.scoreRatio.ratioList[currentMatchesRatioPage].ratioTitle">
        <el-table-column v-for="(subRatioItem, index) in matches.scoreRatio.ratioList[currentMatchesRatioPage].ratioSubItem" :key="subRatioItem.key" min-width="200px" :label="subRatioItem.ratioTitle + '(' + subRatioItem.ratioScore + ')'">
          <template #default="scope">

            <div v-if="matches.scoreMode == 0">
              <el-radio-group 
               v-model="matches.contestant[scope.$index].contestantScore[currentMatchesRatioPage].subScore[index].score" 
               @change="changeDataByPassOrFail(scope.$index, index, matches.contestant[scope.$index].contestantScore[currentMatchesRatioPage].subScore[index].score)">
                <el-radio-button :label="1">通过</el-radio-button>
                <el-radio-button :label="2">不通过</el-radio-button>
              </el-radio-group>
            </div>

            <div v-if="matches.scoreMode == 1">
              <el-radio-group v-model="matches.contestant[scope.$index].contestantScore[currentMatchesRatioPage].subScore[index].score" @change="changeDataByWord(scope.$index, index, matches.contestant[scope.$index].contestantScore[currentMatchesRatioPage].subScore[index].score)">
                <el-radio-button :label="4">A</el-radio-button>
                <el-radio-button :label="7">B</el-radio-button>
                <el-radio-button :label="10">C</el-radio-button>
                <el-radio-button :label="11">D</el-radio-button>
              </el-radio-group>
            </div>

            <div v-if="matches.scoreMode == 2">
              <el-input-number v-model="matches.contestant[scope.$index].contestantScore[currentMatchesRatioPage].subScore[index].score" @change="changeDataByInput(scope.$index, index, matches.contestant[scope.$index].contestantScore[currentMatchesRatioPage].subScore[index].score)" :min="0" :max="subRatioItem.ratioScore" step="1" controls-position="right" />
            </div>

          </template>
        </el-table-column>
      </el-table-column>

      <el-table-column min-width="250px" width="250px" label="总成绩">
        <template #default="scope">

          <div v-if="matches.scoreMode == 0">
            <el-radio-group 
              v-model="matches.contestant[scope.$index].scoreAll" 
              @change="changeScreenData()">
              <el-radio-button :label="1">通过</el-radio-button>
              <el-radio-button :label="2">不通过</el-radio-button>
            </el-radio-group>
          </div>

          <div v-if="matches.scoreMode == 1">
            <el-select v-model="matches.contestant[scope.$index].scoreAll" placeholder="">
              <el-option v-for="(item, index) in contestantScoreTextListByWordMode" :key="item.key" :label="item" :value="index+3" 
               @click.native="changeScreenData()" />
            </el-select>
          </div>

          <div v-if="matches.scoreMode == 2">
            <el-input-number
              v-model="matches.contestant[scope.$index].scoreAll"
              @change="changeScreenData()"
              :min="0" :max="matches.scoreRatio.ratioScoreAll" step="1" controls-position="right" />
          </div>

        </template>
      </el-table-column>
    </el-table>

    <div class="centerList">
      <el-button type="primary" size="large" @click="draftPaperDialog()">草稿纸</el-button>
      <el-button type="primary" size="large" @click="tipsDialog()">考级注意事项</el-button>
      <el-button type="primary" size="large" @click="screen()">投屏</el-button>
      <el-button type="primary" size="large" @click="settings()">设置</el-button>
      <el-button type="primary" size="large" @click="examFinish()">结束考级</el-button>
      <el-button type="primary" size="large" @click="selectContestantDialog()">手动选择选手</el-button>
      <el-button type="primary" size="large" @click="setContestantWaiverDialog()">当前考核中有选手弃考</el-button>
      <el-button type="primary" size="large" @click="saveExamResult()">保存数据</el-button>
      <el-button type="primary" size="large" @click="saveExamResultAndNextContestant()">保存并切换下一批</el-button>
    </div>
  </div>

  <el-dialog v-model="selectMatchesShow" title="选择考级" :before-close="DialogDone">
    <el-form :model="form">
      <el-form-item label="考级：" label-width="100px">
        <el-select v-model="currentMatchesIndex" placeholder="请选择" style="width: 50%">
          <el-option v-for="(item, index) in matchesList" :key="item.key" :label="item.name" :value="index"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考官选择：" label-width="100px">
        <el-select v-model="examinerIndex" placeholder="请选择" style="width: 50%">
          <el-option v-for="(item, index) in matchesList[currentMatchesIndex].examiner.examinerNameList" :key="item.key" :label="item" :value="index"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="selectMatches()">选择该考级</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="selectContestantShow" width="80%" title="选择选手">
    <div class="rowList">
      <p class="rowList-item">选择段带/级位</p>
      <el-select class="rowList-item" v-model="currentSelectContestantGrade" @change="selectContestantBySearch()">
        <el-option v-for="(item, index) in selectContestantGradeList" :key="item.key" :label="item" :value="index" />
      </el-select>

      <p class="rowList-item">选择批次</p>
      <el-select class="rowList-item" v-model="currentSelectContestantBatch" @change="selectContestantBySearch()">
        <el-option :key="0" label="全部批次" :value="0" />
        <el-option v-for="item in contestantMaxBatch" :key="item.key" :label="item" :value="item" />
      </el-select>

      <el-button class="rowList-item" type="primary" size="large" @click="changeContestantByCurrentGradeNoComplete()">自动选择该页面未考核人员</el-button>
    </div>
    <el-table ref="multipleTableRef" :data="contestantList" stripe style="width: 100%; height: 400px" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}">
      <el-table-column label="选择">
        <template #default="scope">
          <el-checkbox v-model="contestantList[scope.$index].isChecked" />
        </template>
      </el-table-column>
      <el-table-column prop="isCompleteText" label="是否完成考核" />
      <el-table-column prop="studentId" label="身份证" />
      <el-table-column prop="studentName" label="姓名" />
      <el-table-column prop="nextGradeText" label="考核段带" />
      <el-table-column prop="contestantBatch" label="上场顺序（批次）" />
      <el-table-column prop="contestantIndex" label="上场顺序（序号）" />
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="changeContestant()">选择该选手</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="selectContestantOneShow" width="80%" title="切换单个选手">
    <div class="rowList">
      <p>输入姓名查询：</p>
      <el-input v-model="currentContestantByOneNameSearch" style="width: 200px" placeholder="请输入姓名查询" @input="changeContestantByOneContestantSearch()" />
    </div>
    <el-table ref="multipleTableRef" :data="contestantList" stripe height="70ch" max-height="80ch" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}">
      <el-table-column prop="isCompleteText" label="是否完成考核" />
      <el-table-column prop="studentId" label="身份证" />
      <el-table-column prop="studentName" label="姓名" />
      <el-table-column prop="nextGradeText" label="考核段带" />
      <el-table-column prop="contestantBatch" label="上场顺序（批次）" />
      <el-table-column prop="contestantIndex" label="上场顺序（序号）" />
      <el-table-column fixed="right" label="选择">
        <template #default="scope">
          <el-button type="primary" size="large" @click="changeContestantByOneContestant(contestantList[scope.$index])">选择</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>

  <el-dialog v-model="setContestantWaiverShow" title="设置选手弃考">
    <el-table ref="multipleTableRef" :data="contestantList" stripe style="width: 100%; height: 400px" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}">
      <el-table-column label="选择">
        <template #default="scope">
          <el-checkbox v-model="matches.contestant[scope.$index].isWaiver" />
        </template>
      </el-table-column>
      <el-table-column prop="studentId" label="身份证" />
      <el-table-column prop="studentName" label="姓名" />
      <el-table-column prop="nextGradeText" label="考核段带" />
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="saveContestantWaiver()">完成</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="settingsShow" title="设置页面">
    <el-form :model="formData" :label-position="labelPosition" label-width="200px" @submit.native.prevent>
      <el-form-item label="基础设置："></el-form-item>
      <el-form-item label="单次考级人数：">
        <el-input-number v-model="settingsItem.contestantLengthMax" :min="1" :max="10" controls-position="right" />
      </el-form-item>
      <el-form-item label="投屏界面是否显示总成绩：">
        <el-radio-group v-model="settingsItem.scoreAllisShow">
          <el-radio-button :label="true">显示总成绩</el-radio-button>
          <el-radio-button :label="false">不显示总成绩</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="分数模式设置：">
        <el-popconfirm width="220" confirm-button-text="确定" cancel-button-text="取消" :icon="InfoFilled" icon-color="#626AEF" title="此按钮点击时，若保存设置数据，会重置所有成绩，是否继续" @confirm="scoreModeByInputChange()">
            <template #reference>
              <el-button :disabled="settingsItem.scoreModeByInputChange">点击编辑分数模式设置</el-button>
            </template>
        </el-popconfirm>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="saveSettings()">保存设置信息</el-button>
        <el-button type="primary" @click="noSaveSettings()">返回</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="tipsDialogShow" title="考级内容注释">
    <div v-if="this.matches.scoreMode == 1">
      <p>A/B/C/D模式 注意事项：</p>
      <p v-if="this.matches.scoreMode == 1">&ensp;A成绩为100%, B成绩为75%, C成绩为50%, D成绩为25%</p>
      <p v-if="this.matches.scoreMode == 1">&ensp;A/B/C/D模式下总成绩中：</p>
      <p v-if="this.matches.scoreMode == 1">&ensp;&ensp;A+: 95%-100%, A: 90%-95%, A-: 85%-90%</p>
      <p v-if="this.matches.scoreMode == 1">&ensp;&ensp;B+: 80%-85%, B: 75%-80%, B-: 70%-75%</p>
      <p v-if="this.matches.scoreMode == 1">&ensp;&ensp;C+: 65%-70%, C: 60%-65%, D: &lt; 60%</p>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="tipsDialogShow = false">返回</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="draftPaperShow" title="草稿纸" width="80%">
    <div class="centerList">
      <el-button type="primary" size="large" @click="clearDraftPaperData()">清空草稿纸</el-button>
    </div>
    <div>
      <el-input class="text" v-model="draftPaperData" type="textarea" :autosize="{ minRows: 20 }" placeholder="草稿纸" />
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="draftPaperShow = false">返回</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
  import matchesModel from '@/models/matches'
  import interaction from '@/utils/interaction'
  import user from '@/models/user'
  import matchesPresenter from '@/presenter/matches_Presenter'
  import dataJudgment from '@/utils/dataJudgment'

  export default {
      name: 'matches',
      props: {
          msg: String
      },
      data() {
          return {
            //dialog控制
            //显示赛事列表
            selectMatchesShow: false,
            //显示选手列表
            selectContestantShow: false,
            //显示切换单个选手列表
            selectContestantOneShow: false,
            //显示设置列表
            settingsShow: false,
            //显示提示列表
            tipsDialogShow: false,
            //显示草稿纸列表
            draftPaperShow: false,
            //显示弃考列表
            setContestantWaiverShow: false,

            //考级数据
            currentMatchesIndex: 0,
            matchesList: undefined,
            matches: undefined,
            examinerIndex: 0,

            //显示选手列表，用于临时显示所有或筛选的结果-主显示在matches.contestant内
            contestantList: undefined,
            
            //选择人员页面-等级列表
            selectContestantGradeList: [],
            currentSelectContestantGrade: 0,
            contestantMaxBatch: 0,
            currentSelectContestantBatch: 0,

            //切换人员
            //存储需要更换的人员信息
            currentContestantByOneIndex: -1,
            currentContestantByOne: undefined,
            //用于切换人员姓名查询
            currentContestantByOneNameSearch: "",
            
            //当前选择的考核项
            currentMatchesRatioPage: 0,
            currentMatchesRatioPageList: [],

            //当前段带-文字
            currentNextGradeText: undefined,

            //ABCD模式列表
            contestantScoreTextListByWordMode: undefined,

            //草稿纸数据
            draftPaperData: "",

            //设置信息-原先数据
            oldSettingsItem: {
              contestantLengthMax: 5,
              //总成绩是否显示
              scoreAllisShow: true,
              //输入成绩模式修改
              scoreModeByInputChange: false,
            },
            //设置信息-实时修改数据
            settingsItem: {
              contestantLengthMax: 5,
              //总成绩是否显示
              scoreAllisShow: true,
              //输入成绩模式修改
              scoreModeByInputChange: false,
            }
          }
      },
      mounted() {
        this.initData();
      },
      methods: {
        initData() {
          this.contestantScoreTextListByWordMode = matchesModel.getMatchesContestantScoreTextListByWordMode();

          user.userIsLogin(
            () => {
              this.initMatchesListData();
            }
          );
        },
        initMatchesListData() {
          matchesModel.showMatchesList(
            (res) => {
              this.matchesList = res;
              this.selectMatchesShow = true;
            },
            (res) => {
              interaction.showErrorMessage(res);
            }
          )
        },
        //选择完考级后才初始化的函数
        initDataByMatchesExists(res) {
          this.initMatchesData(res);

          //关闭考级选项
          this.selectMatchesShow = false;
          //打开设置选项
          this.settingsShow = true;
        },
        initMatchesData(res) {
          this.matches = res;
          if(this.matches.contestant == undefined) {
            this.matches.contestant = [];
          }

          //初始化选择选手页面，选手通过段带选择
          this.selectContestantGradeList.push("全选")
          this.selectContestantGradeList = this.selectContestantGradeList.concat(this.matches.gradeGroup);


          for(var item of this.matches.scoreRatio) {
            if(item.nextGrade <= 0) {
              continue;
            }
            
          }

          for(var item of this.matches.contestant) {
            item.contestantScore = item.examinerScore[this.examinerIndex];
            item.examinerScore = undefined;
          }

          //找到最大的批次下标
          var maxBatch = 0;
          for(var item of this.matches.contestant) {
            if(maxBatch < item.contestantBatch) {
              maxBatch = item.contestantBatch;
            }
          }
          this.contestantMaxBatch = maxBatch;

          matchesPresenter.changeMatches(res);
          this.nextContestant();
        },

        clearScoreAll() {
          matchesPresenter.clearScoreAll();
        },

        /**************************分数处理函数******************************************/

        //根据模式刷新总成绩
        refreshScoreAll() {
          switch(this.matches.scoreMode) {
          case 0:
            this.updateScoreByPassOrFail();
            break;
          case 1:
            this.updateScoreByWord();
            break
          case 2:
            this.updateScoreByInput();
            break;
          }
        },

        //数据修改-通过/不通过
        changeDataByPassOrFail(contestantIndex, subScoreIndex, score = -1) {
          var contestant = this.matches.contestant;
          var contestantMaxLength = contestant.length - 1;

          //一键操作-所有人的某子考核项
          if(contestantIndex == contestantMaxLength) {
            for(var contestantItem of contestant) {
              contestantItem.contestantScore[this.currentMatchesRatioPage].subScore[subScoreIndex].score = score;
            }
            //总成绩同步
            this.updateScoreByPassOrFail();
          }
          //单独某个考核子项
          else {
            this.updateScoreByPassOrFail(contestantIndex);
          }

          this.changeScreenData();
        },

        //通过/不通过模式下更新总成绩数据
        updateScoreByPassOrFail(contestantIndex = -1) {
          var contestant = this.matches.contestant;
          var that = this;

          //数据更新函数
          var update = function(contestantItem) {
            var scoreRatio = that.matches.scoreRatio;
            var ratioPage = that.currentMatchesRatioPage;

            var scoreAll = 0;
            var ratioScoreAll = 0;
            for(var ratioPage = 0 ; ratioPage < contestantItem.contestantScore.length ; ratioPage ++) {
              ratioScoreAll += scoreRatio.ratioList[ratioPage].ratioScore;

              for(var subScoreIndex = 0 ; subScoreIndex < contestantItem.contestantScore[ratioPage].subScore.length ; subScoreIndex ++) {
                var ratioScore = parseInt(scoreRatio.ratioList[ratioPage].ratioSubItem[subScoreIndex].ratioScore);
                var scorePercent = matchesPresenter.getPassOrFailPercent(contestantItem.contestantScore[ratioPage].subScore[subScoreIndex].score)
                scoreAll += ratioScore * scorePercent;
              }
            }
            scoreAll = scoreAll / ratioScoreAll;
            contestantItem.scoreAll = matchesPresenter.getPassOrFailByPercent(scoreAll);
            
          };

          //所有选手都要更新
          if(contestantIndex == -1) {
            for(var contestantItem of contestant) {
              update(contestantItem);
            }
            return;
          }

          update(contestant[contestantIndex]);
        },

        //数据修改-字母模式
        changeDataByWord(contestantIndex, subScoreIndex, score = -1) {

          var contestant = this.matches.contestant;
          var contestantMaxLength = contestant.length - 1;

          //一键操作-所有人的某子考核项
          if(contestantIndex == contestantMaxLength) {
            for(var contestantItem of contestant) {
              contestantItem.contestantScore[this.currentMatchesRatioPage].subScore[subScoreIndex].score = score;
            }
            //总成绩同步
            this.updateScoreByWord();
          }
          //单独某个子考核项
          else {
            this.updateScoreByWord(contestantIndex);
          }

          this.changeScreenData();
        },

        //字母模式下更新总成绩数据
        updateScoreByWord(contestantIndex = -1) {
          var contestant = this.matches.contestant;
          var that = this;

          //数据更新函数
          var update = function(contestantItem) {
            var scoreRatio = that.matches.scoreRatio;
            var ratioPage = that.currentMatchesRatioPage;

            var scoreAll = 0;
            var ratioScoreAll = 0;
            for(var ratioPage = 0 ; ratioPage < contestantItem.contestantScore.length ; ratioPage ++) {
              ratioScoreAll += scoreRatio.ratioList[ratioPage].ratioScore;

              for(var subScoreIndex = 0 ; subScoreIndex < contestantItem.contestantScore[ratioPage].subScore.length ; subScoreIndex ++) {
                var ratioScore = parseInt(scoreRatio.ratioList[ratioPage].ratioSubItem[subScoreIndex].ratioScore);
                var scorePercent = matchesPresenter.getWordButtonPercent(contestantItem.contestantScore[ratioPage].subScore[subScoreIndex].score)
                scoreAll += ratioScore * scorePercent;
              }
            }
            scoreAll = scoreAll / ratioScoreAll;
            contestantItem.scoreAll = matchesPresenter.getWordButtonByPercent(scoreAll);
          };

          //所有选手都要更新
          if(contestantIndex == -1) {
            for(var contestantItem of contestant) {
              update(contestantItem);
            }
            return;
          }

          update(contestant[contestantIndex]);
        },

        //数据修改-输入模式
        changeDataByInput(contestantIndex, subScoreIndex, score = -1) {
          var contestant = this.matches.contestant;
          var contestantMaxLength = contestant.length - 1;

          //一键操作-所有人的某子考核项
          if(contestantIndex == contestantMaxLength) {
            for(var contestantItem of contestant) {
              contestantItem.contestantScore[this.currentMatchesRatioPage].subScore[subScoreIndex].score = score;
            }
            //总成绩同步
            this.updateScoreByInput();
          }
          //单独某个子考核项
          else {
            this.updateScoreByInput(contestantIndex);
          }

          this.changeScreenData();
        },

        //输入模式下更新总成绩数据
        updateScoreByInput(contestantIndex = -1) {
          var contestant = this.matches.contestant;
          var that = this;

          var update = function(contestantItem) {
            var scoreAll = 0;
            for(var item of contestantItem.contestantScore) {
              for(var subItem of item.subScore) {
                scoreAll += subItem.score;
              }
            }

            contestantItem.scoreAll = scoreAll;
          }

          //所有选手都要更新
          if(contestantIndex == -1) {
            for(var contestantItem of contestant) {
              update(contestantItem);
            }
            return;
          }

          update(contestant[contestantIndex]);
        },

        //修改投屏分数
        changeScreenData() {
          var matches = JSON.parse(JSON.stringify(this.matches));

          matches.settingsItem = this.settingsItem;

          matches.contestant = this.matches.contestant.slice(0, this.matches.contestant.length - 1);

          matches.currentMatchesRatioPage = this.currentMatchesRatioPage;

          localStorage.setItem("screenData", JSON.stringify(matches));
        },

        /**************************分数处理函数******************************************/

        DialogDone(done) {
          if(this.matches == undefined) {
            this.$router.push("/");
          }
          done();
        },

        /****************窗口打开函数******************/
        tipsDialog() {
          this.tipsDialogShow = true;
        },
        settings() {
          this.settingsShow = true;
        },
        draftPaperDialog() {
          this.draftPaperShow = true;
        },
        selectContestantDialog() {
          this.selectContestantBySearch();
          this.selectContestantShow = true;
        },
        setContestantWaiverDialog() {
          this.contestantList = this.matches.contestant.slice(0, -1);

          this.setContestantWaiverShow = true;
        },
        changeContestantByOneContestantDialog(index) {
          this.currentContestantByOneIndex = index;
          this.changeContestantByOneContestantSearch();
          this.selectContestantOneShow = true;
        },
        /****************窗口打开函数******************/



        /****************事件处理函数******************/
        selectMatches() {
          var matchesItem = this.matchesList[this.currentMatchesIndex];
          var currentMatchesId = matchesItem.matchesId;
          var examinerAmount = matchesItem.examinerAmount;

          if(!dataJudgment.dataIsValue(currentMatchesId)) {
            interaction.showErrorMessage("请选择考级！");
            return;
          }

          if(this.examinerIndex < 0 || this.examinerIndex >= examinerAmount) {
            interaction.showErrorMessage("考官选择有误，请重新选择！");
            return;
          }

          matchesModel.showMatches(
            currentMatchesId,
            (res) => {
              if(res.contestant == undefined || res.contestant.length <= 0) {
                interaction.showErrorMessage("该考级无选手信息，请先添加选手后重试！");
                return;
              }

              this.initDataByMatchesExists(res);
              matchesModel.changeMatchesGoingOnStateState(
                this.matches.matchesId, () => {},
                (res) => {
                  interaction.showErrorMessage(res);
                }
              )
            },
            (res) => {
              interaction.showErrorMessage(res);
            }
          )
        },
        //切换下一批选手
        nextContestant() {
          this.matches.contestant = matchesPresenter.selectNextContestantByDefault(this.settingsItem.contestantLengthMax);

          if(this.matches.contestant.length <= 0) {
            this.currentNextGradeText = "";

            interaction.showSuccessMessage("当前所有选手均已考核完毕，请点击结束考级或单独选择某一选手重新进行考核");
            return;
          }

          this.nextContestantManage();
        },
        //切换下一批选手-根据列表切换
        nextContestantByList(studentList) {
          this.matches.contestant = matchesPresenter.selectNextContestantByList(studentList);
          this.nextContestantManage();
        },
        //切换选手处理
        nextContestantManage() {
          var nextGrade = this.matches.contestant[0].nextGrade;

          this.currentNextGradeText = matchesModel.getGradeText(this.matches, nextGrade);
          this.matches.scoreRatio = matchesPresenter.getScoreRatioByNextGrade(nextGrade);

          var contestant = this.matches.contestant;
          var scoreRatio = this.matches.scoreRatio.ratioList;

          //创建一个选手用于存放一键操作
          contestant.push({
            studentName: "一键操作",
            scoreAll: 0,
            contestantScore: [],
          })

          var newContestantScoreList = contestant[contestant.length - 1].contestantScore;
          for(var scoreRatioItem of scoreRatio) {
            newContestantScoreList.push({
              score: 0,
              subScore: [],
            })
            var newContestantSubScoreList = newContestantScoreList[newContestantScoreList.length - 1].subScore;
            for(var scoreRatioSubItem of scoreRatioItem.ratioSubItem) {
              newContestantSubScoreList.push({
                score: 0,
              })
            }
          }

          //重置考核项选择
          this.currentMatchesRatioPage = 0;
          this.currentMatchesRatioPageList = matchesPresenter.getScoreRatioTextListByScoreRatio(this.matches.scoreRatio);
          
          //刷新总成绩
          this.refreshScoreAll();

          this.changeScreenData();
        },
        //切换选手-根据手动选择的结果切换
        changeContestant() {
          var studentList = [];
          var checkCount = 0;

          for(var i = 0 ; i < this.contestantList.length ; i ++) {
            if(this.contestantList[i].isChecked) {
              checkCount += 1;
              studentList.push(this.contestantList[i]);
            }
          }

          if(checkCount > this.settingsItem.contestantLengthMax) {
            interaction.showErrorMessage("选择数不得超过" + this.settingsItem.contestantLengthMax + "个");
            return;
          }

          if(checkCount <= 0) {
            this.selectContestantShow = false;
            return;
          }

          this.selectContestantShow = false;
          this.nextContestantByList(studentList);
        },
        //切换选手-根据当前等级没有完成考级的人员
        changeContestantByCurrentGradeNoComplete() {
          var count = this.settingsItem.contestantLengthMax;
          var studentList = [];
          var selectGrade = undefined;
          //找到未完成的下标后记录
          var firstRow = 0;

          for(var i = 0 ; i < this.contestantList.length ; i ++) {
            var item = this.contestantList[i];
            if(!item.isComplete) {
              selectGrade = item.nextGrade;
              firstRow = i;
              break;
            }
          }

          for(var i = firstRow; i < this.contestantList.length ; i ++) {
            var item = this.contestantList[i];
            if(selectGrade != item.nextGrade) {
              break;
            }
            if(!item.isComplete) {
              studentList.push(item);
              count --;
            }
            if(count <= 0) {
              break;
            }
          }

          this.selectContestantShow = false;
          this.nextContestantByList(studentList);
        },
        //修改选手，单个选手切换-筛选
        changeContestantByOneContestantSearch() {
          let data = this.matches.contestant[this.currentContestantByOneIndex];

          let contestant = matchesPresenter.getMatchesData().contestant;
          if(contestant.length <= 0) {
            return;
          }

          let nextGrade = -1;
          if(this.matches.contestant.length <= 0) {
            return;
          }
          nextGrade = this.matches.contestant[0].nextGrade;

          let newContestantList = [];
          for(var item of contestant) {
            let needShow = true;
            needShow = needShow & item.nextGrade == nextGrade;
            needShow = needShow & !(item.studentId == data.studentId && item.nextGrade == data.nextGrade);
            needShow = needShow & item.studentName.indexOf(this.currentContestantByOneNameSearch) >= 0;
            if(needShow) {
              item.nextGradeText = matchesModel.getGradeText(this.matches, item.nextGrade);
              item.isCompleteText = item.isComplete?"完成": "未完成";
              newContestantList.push(item);
            }
          }

          this.contestantList = newContestantList;
          this.currentContestantByOne = data;
        },
        //修改选手，单个选手切换
        changeContestantByOneContestant(data) {
          let currentIndex = this.currentContestantByOneIndex;
          let currentItem = this.currentContestantByOne;

          for(let [index, item] of this.matches.contestant.entries()) {
            if(item.studentId == data.studentId && item.nextGrade == data.nextGrade) {
              this.matches.contestant[index] = currentItem;
            }
          }

          this.matches.contestant[currentIndex] = data;

          this.selectContestantOneShow = false;
        },
        saveContestantWaiver() {
          this.setContestantWaiverShow = false;

          this.changeScreenData();
        },
        scoreModeByInputChange() {
          if(this.matches.scoreMode != "2") {
            interaction.showErrorMessage("当前模式不是分数填写模式！")
            return;
          }
          this.settingsItem.scoreModeByInputChange = true;
        },
        saveSettings() {
          if(this.settingsItem.contestantLengthMax != this.oldSettingsItem.contestantLengthMax) {
            this.nextContestant();
          }

          if(this.settingsItem.scoreModeByInputChange != this.oldSettingsItem.scoreModeByInputChange) {
            this.clearScoreAll();
          }

          this.settingsShow = false;
          this.oldSettingsItem.contestantLengthMax = this.settingsItem.contestantLengthMax;
          this.oldSettingsItem.scoreAllisShow = this.settingsItem.scoreAllisShow;
          this.settingsItem.scoreModeByInputChange = this.oldSettingsItem.scoreModeByInputChange;

          this.changeScreenData();
        },
        noSaveSettings() {
          this.settingsShow = false;
          this.settingsItem.contestantLengthMax = this.oldSettingsItem.contestantLengthMax;
          this.settingsItem.scoreAllisShow = this.oldSettingsItem.scoreAllisShow;
          this.settingsItem.scoreModeByInputChange = this.oldSettingsItem.scoreModeByInputChange;
        },
        clearDraftPaperData() {
          this.draftPaperData = "";
        },
        screen() {
          this.changeScreenData();
          const screen = this.$router.resolve("/matches/screen");
          window.open(screen.href, '_blank');
        },
        examFinish() {
          matchesModel.changeMatchesScoreProcessingState(
            this.matches.matchesId,
            () => {
              interaction.showSuccessMessage("考级已结束，数据待审核");
              this.$router.push("/")
            },
            (res) => {
              interaction.showErrorMessage(res);
            }
          )
        },
        selectContestantBySearch() {
          var contestantList = matchesPresenter.getMatchesData().contestant;
          var newContestantList = [];
          var currentGrade = this.currentSelectContestantGrade;
          var currentBatch = this.currentSelectContestantBatch;

          for(var item of contestantList) {
            var nextGrade = item.nextGrade;
            var contestantBatch = item.contestantBatch;

            var needShow = true;
            needShow = needShow & (currentGrade == 0 || currentGrade == nextGrade);
            needShow = needShow & (currentBatch == 0 || currentBatch == contestantBatch);

            if(!needShow) {
              continue;
            }

            item.isChecked = false;
            item.nextGradeText = matchesModel.getGradeText(this.matches, item.nextGrade);
            item.isCompleteText = item.isComplete? "完成": "未完成";
            newContestantList.push(item);
          }

          this.contestantList = newContestantList;
        },
        saveExamResult() {
          matchesPresenter.saveContestantExamResult(this.matches.contestant, this.examinerIndex);
        },
        saveExamResultAndNextContestant() {
          matchesPresenter.saveContestantExamResult(this.matches.contestant, this.examinerIndex);
          this.nextContestant();
        },
        /****************事件处理函数******************/
      },
  }
</script>

<style src="@/css/view.css" scoped></style>
<style src="@/css/information.css" scoped></style>
<style scoped>

  .image {
    width: 50px; 
    height: 75px;
  }
  .text {
    font-size: large;
  }
</style>
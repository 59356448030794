<template>
    <div class="rowList">
        <p>每批最大人数：</p>
        <el-input-number class="rowList-item" v-model="contestantIndexMaxLength" :min="1" :max="99" />

        <el-button class="rowList-item" type="primary" size="large" @click="autoOrder()">按照批次最大人数自动排序</el-button>
        <el-button class="rowList-item" type="primary" size="large" @click="disorganizeOrder()">按照批次最大人数自动排序（打乱顺序）</el-button>
        <el-button class="rowList-item" type="primary" size="large" @click="refershContestantOrder()">刷新排序</el-button>
        <el-button class="rowList-item" type="primary" size="large" @click="saveContestantIndex()">保存该顺序</el-button>
    </div>

    <div class="rowList">
        <p class="rowList-item">条件筛选：</p>

        <p>ID筛选：</p>
        <el-input class="rowList-item" style="width: 200px;" v-model="searchId" placeholder="请输入ID" @input="refershContestantShow()" />

        <p>姓名筛选：</p>
        <el-input class="rowList-item" style="width: 100px;" v-model="searchName" placeholder="请输入姓名" @input="refershContestantShow()" />

        <p>等级筛选：</p>
        <el-select class="rowList-item m-2" style="width: 150px;" v-model="currentSearchGradeIndex" placeholder="等级" @change="refershContestantShow()">
            <el-option v-for="(item, index) in gradeGroup" :key="index" :label="item" :value="index" />
        </el-select>

        <p>批次筛选：</p>
        <el-select class="rowList-item m-2" style="width: 100px;" v-model="currentSearchBatchIndex" placeholder="等级" @change="refershContestantShow()">
            <el-option :key="0" label="全部批次" :value="0" />
            <el-option v-for="item in contestantBatchMaxLength" :key="item" :label="item" :value="item" />
        </el-select>

        <el-button type="primary" size="large" @click="resetSearch()">重置条件</el-button>
    </div>

    <div class="rowList">
        <p>修改选中人员的批次为：</p>
        <el-select class="rowList-item m-2" style="width:100px" v-model="useContestantBatchData" placeholder="上场顺序（批次）">
            <el-option v-for="item in contestantBatchMaxLength" :key="item" :label="item" :value="item" />
        </el-select>
        <el-button class="rowList-item" type="primary" size="large" @click="changeUseContestantBatch()">修改</el-button>
    </div>

    <el-table id="contestantIndexTable" :data="showContestant" style="width: 100%;" height="60vh" row-key="rowKey" :span-method="tableSpanMethod" :row-class-name="tableColor" :border="true" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}" @selection-change="tableSelection">
        <el-table-column type="selection" width="55" />
        <el-table-column label="批次" width="100">
            <template #default="scope">
                {{'第' + scope.row.contestantBatch + '批'}}
            </template>
        </el-table-column>
        <el-table-column label="顺序" width="100">
            <template #default="scope">
                {{scope.row.contestantIndex + '号'}}
            </template>
        </el-table-column>
        <el-table-column prop="studentId" label="ID" width="200" />
        <el-table-column prop="studentName" label="姓名" width="100" />
        <el-table-column prop="nextGradeText" label="目标等级" />
        
        <el-table-column label="上场顺序（批次）" width="200">
            <template #default="scope">
                <el-input-number class="mx-4" v-model="showContestant[scope.$index].contestantBatch" :step="1" :min="0" :max="contestantBatchMaxLength" />
            </template>
        </el-table-column>
        <el-table-column label="上场顺序（顺序）"  width="200">
            <template #default="scope">
                <el-input-number v-model="showContestant[scope.$index].contestantIndex" :step="1"  :min="0" :max="99" />
            </template>
        </el-table-column>
        <!-- <el-table-column label="上场顺序（批次）" width="100">
            <template #default="scope">
                <el-select v-model="showContestant[scope.$index].contestantBatch" class="m-2" placeholder="上场顺序（批次）">
                    <el-option v-for="item in contestantBatchMaxLength" :key="item" :label="item" :value="item" />
                </el-select>
            </template>
        </el-table-column>
        <el-table-column label="上场顺序（顺序）"  width="100">
            <template #default="scope">
                <el-select v-model="showContestant[scope.$index].contestantIndex" class="m-2" placeholder="上场顺序（顺序）">
                    <el-option v-for="item in contestantIndexMaxLength" :key="item" :label="item" :value="item" />
                </el-select>
            </template>
        </el-table-column> -->
    </el-table>

</template>

<script>
    import Sortable from 'sortablejs'

    import matchesModel from '@/models/matches'
    import interaction from '@/utils/interaction'


    export default {
        name: 'contestantIndex',
        props: {
            msg: String,
            matchesId: String,
        },
        data() {
            return {
                contestant: undefined,
                showContestant: undefined,
                selectionContestant: [],

                tableMergeList: [],
                
                contestantBatchMaxLength: 99,
                contestantIndexMaxLength: 5,

                searchId: "",
                searchName: "",
                gradeGroup: [],
                currentSearchGradeIndex: 0,
                currentSearchBatchIndex: 0,

                //用于记录选中修改批次控件的变量
                useContestantBatchData: 1,
            }
        },
        emits: ['refresh'],
        methods: {
            initData() {
                if(this.matchesId == undefined) {
                    this.backView("信息错误，请返回重试！")
                    return;
                }

                matchesModel.showMatches(
                    this.matchesId,
                    (res) => {
                        this.contestant = res.contestant;

                        if(this.contestant.length > 0) {
                            for(var item of this.contestant) {
                                item.rowKey = item.studentId + "_" + item.nextGrade;
                                item.nextGradeText = matchesModel.getGradeText(res, item.nextGrade);
                            }
                        }

                        this.gradeGroup = matchesModel.getGradeTextList(res, "全选");

                        this.refershContestantOrder();
                    },
                    (res) => {
                        this.backView(res);
                    }
                );
            },

            backView(errorTitle) {
                this.$router.push('/matchesControl/show');
                interaction.showErrorMessage(errorTitle);
            },

            //table行拖动
            tableDrag() {
                const tbody = document.getElementById("contestantIndexTable").querySelector('.el-table__body-wrapper tbody')
                const _this = this
                Sortable.create(tbody, {
                    onEnd({ newIndex, oldIndex }) {
                        if(newIndex == oldIndex) {
                            return;
                        }

                        let currentRow = _this.showContestant.splice(oldIndex, 1)[0]
                        _this.showContestant.splice(newIndex, 0, currentRow)

                        //如果插入到第一位,复制后一位的批次
                        if(newIndex <= 0) {
                            _this.showContestant[newIndex].contestantBatch = _this.showContestant[1].contestantBatch;
                        }
                        //不是第一位，则复制上一位的批次
                        else {
                            _this.showContestant[newIndex].contestantBatch = _this.showContestant[newIndex-1].contestantBatch;
                        }
                        
                        _this.tableMergeIndexReOrder();
                        _this.tableMerge();

                        // _this.refershContestantOrder();
                    }
                })
            },
            
            //table行移动后Index重排
            tableMergeIndexReOrder() {
                let showContestant = this.showContestant;

                let currentBatch = -1;
                let currentIndex = 0;
                for(let item of showContestant) {
                    if(currentBatch != item.contestantBatch) {
                        currentBatch = item.contestantBatch;
                        currentIndex = 0;
                    }
                    currentIndex ++;
                    item.contestantIndex = currentIndex;
                }
            },

            //table行合并数据处理
            tableMerge() {
                let showContestant = this.showContestant;
                let tableMergeList = Array.from(Array(showContestant.length), () => ({
                    rowspan: 0,
                    colorMode: 0,
                }))

                let index = 0;
                let colorMode = 1;
                for(let i = 0 ; i < showContestant.length ; i ++) {
                    if(showContestant[i].contestantBatch != showContestant[index].contestantBatch) {
                        index = i;
                        colorMode = colorMode == 1? 2: 1;
                    }
                    tableMergeList[index].rowspan += 1;
                    tableMergeList[i].colorMode = colorMode;
                }

                this.tableMergeList = tableMergeList;
            },

            //table行合并函数
            tableSpanMethod(data) {
                let {rowIndex, columnIndex} = data;
                if (columnIndex === 1) {
                    return {
                        // rowspan: this.tableMergeList[rowIndex].rowspan,
                        // colspan: this.tableMergeList[rowIndex].rowspan > 0? 1: 0,
                        rowspan: 1,
                        colspan: 1,
                    }
                }
            },

            //table行颜色修改
            tableColor({row, rowIndex}) {
                return `color-mode-${this.tableMergeList[rowIndex].colorMode}`
            },

            //打乱相同等级内顺序
            disorganizeOrder() {
                this.contestant = matchesModel.matchesContestantSortByNextGrade(this.contestant);

                var currentNextGrade = -1;
                var currentList = [];
                var newList = [];

                //打乱顺序
                var order = function(contestant) {
                    for (var i = currentList.length - 1; i > 0; i--) {
                        const j = Math.floor(Math.random() * (i + 1));
                        [contestant[i], contestant[j]] = [contestant[j], contestant[i]];
                    }
                    return contestant;
                }

                for(var item of this.contestant) {
                    if(currentNextGrade != item.nextGrade) {
                        newList = newList.concat(order(currentList));
                        currentList = [];
                        currentNextGrade = item.nextGrade;
                    }
                    currentList.push(item)
                }
                newList = newList.concat(order(currentList));

                this.contestant = newList;

                this.autoOrder();
            },

            //自动排序
            autoOrder() {
                var contestantIndexMaxLength = this.contestantIndexMaxLength;
                var currentBatch = 0;
                var currentIndex = 1;
                var currentNextGrade = -1;

                for(var item of this.contestant) {
                    if(currentIndex > contestantIndexMaxLength || item.nextGrade != currentNextGrade) {
                        currentNextGrade = item.nextGrade;
                        currentBatch += 1;
                        currentIndex = 1;
                    }
                    item.contestantBatch = currentBatch;
                    item.contestantIndex = currentIndex;
                    currentIndex += 1;
                }

                this.refershContestantOrder();
            },

            //刷新排序
            refershContestantOrder() {
                this.contestant = matchesModel.matchesContestantSortByBatchAndIndex(this.contestant);
                this.refershContestantShow();
            },

            //刷新显示的数据
            refershContestantShow() {
                this.searchContestantData();
                this.tableMerge();
            },

            //查询筛选
            searchContestantData() {
                var showContestant = [];
                
                if(this.contestant == undefined) {
                    return;
                }

                for(var item of this.contestant) {
                    var nextGrade = item.nextGrade;
                    var contestantBatch = item.contestantBatch;

                    var needShow = true;

                    needShow = needShow & item.studentId.indexOf(this.searchId) >= 0;
                    needShow = needShow & item.studentName.indexOf(this.searchName) >= 0;
                    needShow = needShow & (this.currentSearchGradeIndex <= 0 || this.currentSearchGradeIndex == nextGrade);
                    needShow = needShow & (this.currentSearchBatchIndex <= 0 || this.currentSearchBatchIndex == contestantBatch);

                    if(needShow) {
                        showContestant.push(item);
                    }
                }

                this.showContestant = showContestant;
            },

            

            //重置筛选项
            resetSearch() {
                this.searchId = "";
                this.searchName = "";
                this.currentSearchGradeIndex = 0;
                this.currentSearchBatchIndex = 0;

                this.refershContestantShow();
            },

            //表格复选框内容
            tableSelection(data) {
                this.selectionContestant = data;
            },

            //修改选中的人员的批次
            changeUseContestantBatch() {
                if(this.selectionContestant.length <= 0) {
                    return;
                }
                for(var item of this.selectionContestant) {
                    item.contestantBatch = this.useContestantBatchData
                }

                this.searchContestantData();
            },

            saveContestantIndex() {
                const contestant = this.contestant;
                if(contestant.length <= 0 || contestant == undefined) {
                    interaction.showErrorMessage("错误，请重新进入！");
                    return;
                }

                //通过map判断，如果map存的历史数据与现在数据不同，则有相同的批次内有不同等级的
                var map = new Map();
                for(var item of contestant) {
                    const contestantBatch = item.contestantBatch;
                    if(!map.has(contestantBatch)) {
                        map.set(contestantBatch, item.nextGrade);
                    }
                    if(map.get(contestantBatch) != item.nextGrade) {
                        interaction.showErrorMessage(`批次{${contestantBatch}}内的选手等级不一致，每一个批次内的等级都需一致！`);
                        return;
                    }
                }

                const sendContestant  = [];
                for(var item of contestant) {
                    sendContestant.push({
                        studentId: item.studentId,
                        nextGrade: item.nextGrade,
                        contestantBatch: item.contestantBatch,
                        contestantIndex: item.contestantIndex,
                    })
                }

                matchesModel.changeContestantBatchAndIndex(
                    this.matchesId, 
                    JSON.stringify(sendContestant),
                    (res) => {
                        interaction.showSuccessMessage("保存成功！");
                        this.$emit("refresh");
                    },
                    (res) => {
                        interaction.showErrorMessage(res);
                    }
                );
            }
        },
        mounted(){
            this.tableDrag();
            this.initData();
        }
    }
</script>


<style src="@/css/view.css" scoped></style>
<style src="@/css/information.css" scoped></style>
<style scoped>
    .parentView {
        margin-left: 10%;
        margin-right: 10%;
    }

</style>
<style>
    .el-table .color-mode-1 {
        background: white;
    }
    .el-table .color-mode-2 {
        --el-table-tr-bg-color: var(--el-color-warning-light-9);
    }  
</style>
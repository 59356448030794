<template>
    <div class="parentView">
        <div class="title">
            <h1>考级创建</h1>
        </div>
        
        <el-form v-if="formData != undefined" :model="formData" :label-position="labelPosition" label-width="100px">
            <el-form-item label="考级名称：">
                <el-input v-model="formData.name" />
            </el-form-item>
            <el-form-item label="考级时间：">
                <el-date-picker v-model="formData.time" type="date" placeholder="考级日期选择" :size="large" />
            </el-form-item>
            <el-form-item label="考级地点：">
                <el-input v-model="formData.place" />
            </el-form-item>
            <el-form-item label="考核人员：">
                <el-input v-model="formData.examiner" />
            </el-form-item>
            <el-form-item label="考级模式：">
                <el-select v-model="formData.scoreMode">
                    <el-option v-for="(item, index) in scoreModeList" :key="item" :label="item" :value="index" />
                </el-select>
            </el-form-item>
            <el-form-item label="考核项管理：">

                <el-table :data="formData.scoreRatio" stripe style="width: 100%;" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}">
                    <el-table-column label="考核段带">
                        <template #default="scope">
                            <el-select v-model="formData.scoreRatio[scope.$index].nextGrade" placeholder="选择考核段带">
                                <el-option v-for="(item, index) in gradeTextList" :key="item.key" :label="item" :value="index"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="考核项模板">
                        <template #default="scope">
                            <el-select v-model="formData.scoreRatio[scope.$index].ratioId" placeholder="选择考核项模板">
                                <el-option v-for="(item) in matchesRatioList" :key="item.key" :label="item.name" :value="item.ratioId"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否为通用选项">
                        <template #default="scope">
                            <el-radio-group v-model="formData.scoreRatio[scope.$index].isDefaultRatio">
                                <el-radio-button :label="true">是</el-radio-button>
                                <el-radio-button :label="false">否</el-radio-button>
                            </el-radio-group>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="centerList">
                    <el-button type="primary" size="large" @click="addScoreRatioItem()">添加考核项</el-button>
                    <el-button type="primary" size="large" @click="popScoreRatioItem()">删除考核项</el-button>
                </div>
            </el-form-item>
        </el-form>

        <div class="centerList">
            <el-button type="primary" size="large" @click="createMatches()">创建考级</el-button>
        </div>
    </div>
</template>

<script>
    import matchesModel from '@/models/matches';
    import dataJudgment from '@/utils/dataJudgment';
    import interaction from '@/utils/interaction';
    import matches_ratio from '@/models/matches_ratio';

    export default {
        name: 'create',
        props: {
            msg: String
        },
        data() {
            return {
                formData: undefined,
                matchesRatioList: undefined,
                scoreModeList: undefined,
                gradeTextList: undefined,
            }
        },
        methods: {
            initData() {
                this.formData = {
                    name: "",
                    time: "",
                    place: "",
                    examiner: "",
                    scoreMode: 0,
                    scoreRatio: [],
                }

                matches_ratio.selectMatchesRatioByUserId(
                    (res) => {
                        this.matchesRatioList = res;
                    },
                    (res) => {
                        interaction.showErrorMessage(res);
                    }
                );

                this.scoreModeList = matchesModel.getMatchesScoreModeList();
                // this.gradeTextList = matchesModel.getGradeTextList();

                this.addScoreRatioItem();
            },

            addScoreRatioItem() {
                this.formData.scoreRatio.push(
                    {
                        nextGrade: 0,
                        ratioId: undefined,
                        isDefaultRatio: false,
                    }
                );
            },
            popScoreRatioItem() {
                if(this.formData.scoreRatio.length > 1) {
                    this.formData.scoreRatio.pop();
                }
            },

            createMatches() {
                if(!dataJudgment.dataIsValue(this.formData.name)) {
                    interaction.showErrorMessage("考级名称不能为空！");
                    return;
                }
                if(!dataJudgment.dataIsValue(this.formData.time)) {
                    interaction.showErrorMessage("考级时间不能为空！");
                    return;
                }
                if(!dataJudgment.dataIsValue(this.formData.place)) {
                    interaction.showErrorMessage("考级地点不能为空！");
                    return;
                }
                if(!dataJudgment.dataIsValue(this.formData.examiner)) {
                    interaction.showErrorMessage("考核人员不能为空！");
                    return;
                }

                //考核项判断
                var gradeIsExistsDict = [];
                var isDefaultRatio = false;
                for(var item of this.formData.scoreRatio) {
                    //判断段带是否重复
                    if(gradeIsExistsDict[item.nextGrade]) {
                        interaction.showErrorMessage("每个段带仅能绑定一个考核项模板！");
                        return;
                    }
                    gradeIsExistsDict[item.nextGrade] = true;

                    if(item.nextGrade <= 0 || !dataJudgment.dataIsValue(item.ratioId)) {
                        interaction.showErrorMessage("考核项有空项，请填完所有选项！");
                        return;
                    }

                    //判断默认选项是否存在且只有一个
                    if(item.isDefaultRatio) {
                        if(isDefaultRatio) {
                            interaction.showErrorMessage("考核项默认选项只能有一个！");
                            return;
                        }
                        isDefaultRatio = item.isDefaultRatio;
                    }
                }
                if(!isDefaultRatio) {
                    interaction.showErrorMessage("考核项必须要有一个默认选项！");
                    return;
                }

                this.formData.time = dataJudgment.formatDate(this.formData.time, "-");

                matchesModel.createMatches(
                    this.formData.name, JSON.stringify(this.formData.scoreRatio), this.formData.examiner, 
                    this.formData.time, this.formData.place, this.formData.scoreMode,
                    () => {
                        interaction.showSuccessMessage("创建考级成功！");
                        this.$router.push("/matchesControl/list");
                    },
                    (res) => {
                        interaction.showErrorMessage(res);
                    }
                )
            },
        },
        mounted() {
            this.initData();
        }
    }
</script>

<style src="@/css/view.css" scoped></style>
<style src="@/css/information.css" scoped></style>
<style scoped>
</style>

function dataIsAlive(data) {
  if(data == undefined || data == null || Object.is(data, NaN))
    return false;
  return true;
}

//数据有值 非空情况下判断字符串是否为空
function dataIsValuable(data) {
  if(data == "")
    return false;
  return true;
}

function dataIsValue(data) {
  if(dataIsAlive(data) && dataIsValuable(data))
    return true;
  return false;
}

function dataListIsValue(...data) {
  for(var i = 0 ; i < data.length ; i ++) {
    if(!dataIsValue(data[i]))
      return false;
  }
  return true;
}

function formatDate(date, formatString = undefined) {
  var date = new Date(date)
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var day = date.getDate()
  if(formatString != undefined) {
    return `${year}${formatString}${month}${formatString}${day}`;
  }
  return `${year}年${month}月${day}日`;
}

export default {
  dataIsAlive,
  dataIsValuable,
  dataIsValue,
  dataListIsValue,
  formatDate,
}
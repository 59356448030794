<template>
    <el-container>
        <el-aside width="150px">
            <el-menu :default-active="pageIndex" v-model="pageIndex" @Select="selectPage" mode="vertical" style="height: 100%;">
                <el-menu-item :index="1">考级信息</el-menu-item>
                <el-menu-item :index="2">选手列表</el-menu-item>
                <el-menu-item :index="3">选手添加</el-menu-item>
                <el-menu-item :index="4">选手排序</el-menu-item>
                <el-menu-item :index="5">成绩审核</el-menu-item>
            </el-menu>
        </el-aside>
        <el-main>
            <div class="bottomSpace"></div>
            
            <div v-if="matches != undefined" class="main">
                <div v-show="pageIndex == 1">
                    <div class="viewCard">
                        <div class="cardPadding">
                            <h3>考级信息</h3>
                            <div class="cardItemByInformation">
                                <div>
                                    <p>考级名称：{{ matches.name }}</p>
                                    <p>考级时间：{{ matches.time }}</p>
                                    <p>考级地点：{{ matches.place }}</p>
                                </div>
                                <div>
                                    <p>考官姓名：{{ matches.examiner.examinerName }}</p>
                                    <p>考官编号：{{ matches.examiner.examinerCode }}</p>
                                    <p>考级模式：{{ matches.scoreModeText }}</p>
                                    <p>考级状态：{{ matches.state }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bottomSpace"></div>

                    <div class="viewCard">
                        <div class="cardPadding">
                            <h3>考级操作</h3>
                            <div class="cardItemByButton">
                                <el-button type="primary" size="large" @click="updateState(0)">修改状态-报名状态</el-button>
                                <el-button type="primary" size="large" @click="updateState(1)">修改状态-停止报名状态</el-button>
                                <el-button type="primary" size="large" @click="createQRCode()">生成报名二维码</el-button>
                                <el-popconfirm width="220" confirm-button-text="确定" cancel-button-text="取消" :icon="InfoFilled" icon-color="#626AEF" title="是否删除该考级" @confirm="deleteMatches()">
                                    <template #reference>
                                        <el-button type="primary" size="large">删除考级</el-button>
                                    </template>
                                </el-popconfirm>
                            </div>
                            <h3>信息导出</h3>
                            <div class="cardItemByButton">
                                <el-button type="primary" size="large" @click="downloadContestantIndexXlsx()">导出检录表</el-button>
                                <el-button type="primary" size="large" @click="downloadContestantListXlsx()">导出选手成绩表</el-button>
                                <el-button type="primary" size="large" @click="downloadContestantInformation()">导出选手基本信息表</el-button>
                                
                            </div>
                            <div class="marginBottom"></div>
                            <div class="cardItemByButton">
                                <el-button type="primary" size="large" @click="downloadContestantImageByExamResult(0)">导出考级选手照片</el-button>
                                <el-button type="primary" size="large" @click="downloadGradeApplicationXlsxByExamResult(0)">导出级位申办表</el-button>
                                <el-button type="primary" size="large" @click="downloadContestantImageByExamResult(1)">导出成绩合格的考级选手照片</el-button>
                                <el-button type="primary" size="large" @click="downloadGradeApplicationXlsxByExamResult(1)">导出成绩合格的级位申办表</el-button>
                            </div>
                            
                        </div>
                    </div>

                    <div class="bottomSpace"></div>

                    <div class="viewCard" v-if="matchesScoreRatio != undefined">
                        <div class="cardPadding">
                            <h3>考级信息</h3>
                            <el-tabs v-model="matchesScoreRatioIndex" type="card" @tab-change="matchesScoreRatioChange">
                                <el-tab-pane v-for="(item, index) in matchesScoreRatio" :key="item.key" :label="item.nextGradeText" :name="index"></el-tab-pane>
                            </el-tabs>
                            <el-table :data="matchesScoreRatio[matchesScoreRatioIndex].ratioList" :span-method="matchesScoreRatioSpanMethod" :row-class-name="ratioTableColor" :border="true" style="width: 100%;" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}">
                                <el-table-column prop="ratioTitle" label="考核项名称" />
                                <el-table-column prop="ratioSubTitle" label="考核子项名称" />
                                <el-table-column prop="ratioSubScore" label="考核子项分数" />
                                <el-table-column prop="ratioScore" label="考核项分数" />
                            </el-table>
                        </div>
                    </div>
                </div>
                <div v-show="pageIndex == 2">
                    <el-table :data="matches.contestant" stripe style="width: 100%;" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}">
                        <el-table-column label="图片" width="150px">
                            <template #default="scope">
                                <img :src="matches.contestant[scope.$index].imageUrl" style="width: 100px; height: 100px" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="studentId" label="身份证" />
                        <el-table-column prop="studentName" label="姓名" />
                        <el-table-column label="考核段带">
                            <template #default="scope">
                                <el-select v-model="matches.contestant[scope.$index].newNextGrade" placeholder="请选择">
                                    <el-option v-for="(item, index) in xlsxGradeTextList" :key="item.key" :label="item" :value="index"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="150px">
                            <template #default="scope">
                                <el-button class="marginBottom" type="primary" size="large" @click="changeMatchesContestantNextGrade(scope.$index)">修改段带</el-button>
                                <el-button class="marginBottom" type="primary" size="large" @click="deleteMatchesContestant(scope.$index)">删除该选手</el-button>
                                <el-upload multiple accept=".jpg,.png" :limit="1" :http-request="e => changeContestantImage(e, scope.$index)">
                                    <el-button type="primary" size="large">修改图片</el-button>
                                </el-upload>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div v-show="pageIndex == 3">
                    <div class="marginBottom">
                        <p style="font-size: 16px;">导入数据模式选择：</p>
                        <el-radio-group v-model="addContestantsMode">
                            <el-radio :label="1" size="large" :border="true">默认模式【考核等级从1开始，对应等级分组第1个】</el-radio>
                            <el-radio :label="2" size="large" :border="true">跆拳道模式【考核等级数字：级位从10（白带十级）-1（红黑带1级），段位不受影响】</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="marginBottom">
                        <el-button type="primary" @click="downloadXlsx()">下载Excel模板</el-button>
                    </div>
                    <el-upload multiple accept=".xlsx" :limit="2" show-file-list="false" :file-list="xlsxFileList" :on-change="xlsxFileChange" :http-request="xlsxFileRequest">
                        <el-button type="primary" size="large">上传Excel文件（选手信息表）</el-button>
                    </el-upload>

                    <div v-show="xlsxData != undefined">
                        <el-table :data="xlsxData" stripe style="width: 100%;" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}">
                            <el-table-column label="图片" width="150px">
                                <template #default="scope">
                                    <img :src="xlsxData[scope.$index].imageUrl" @error='xlsxDataImageLoadError(scope.$index)' style="width: 100px; height: 100px" />
                                </template>
                            </el-table-column>
                            <el-table-column prop="studentId" label="身份证" />
                            <el-table-column prop="studentName" label="姓名" />
                            <el-table-column label="考核等级">
                                <template #default="scope">
                                    <el-select v-model="xlsxData[scope.$index].nextGrade" placeholder="请选择">
                                        <el-option v-for="(item, index) in xlsxGradeTextList" :key="item.key" :label="item" :value="index"></el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column label="管理" width="150px">
                                <template #default="scope">
                                    <el-upload v-if="xlsxData[scope.$index].imageLoadError" multiple accept=".jpg,.png" :limit="1" :http-request="e => xlsxImageFileRequest(e, scope.$index)">
                                        <el-button type="primary">添加图片</el-button>
                                    </el-upload>
                                    <el-button type="primary" size="large" @click="deleteCurrentXlsxData(scope.$index)">删除该选手</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="marginBottom centerList">
                            <el-button type="primary" size="large" @click="saveContestants()">添加选手</el-button>
                        </div>
                    </div>
                </div>
                <div v-show="pageIndex == 4">
                    <contestantIndex :matchesId="this.matchesId" @refresh="updateMatches" ref="contestantIndexRef" />
                </div>
                <div v-show="pageIndex == 5">
                    <div v-if="contestantExamReviewList.length > 0">
                        <div class="rowList">
                            <el-button class="rowList-item" size="large" @click="changeExamResult(1)">全选通过</el-button>
                            <el-button class="rowList-item" size="large" @click="changeExamResult(2)">全选不通过</el-button>
                            <el-button class="rowList-item" size="large" type="primary" @click="saveExamResult()">成绩确认</el-button>
                            <!-- <el-button class="rowList-item" type="primary" @click="examDetermineShow = false">关闭</el-button> -->
                        </div>
                     
                        <el-tabs v-model="contestantExamReviewNextGradeIndex" type="card" @tab-change="matchesScoreRatioChange">
                            <el-tab-pane v-for="(item, index) in contestantExamReviewList" :key="item.key" :label="item.nextGradeText" :name="index"></el-tab-pane>
                        </el-tabs>

                        <el-table :data="contestantExamReviewList[contestantExamReviewNextGradeIndex].contestant" stripe style="width: 100%;" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}">
                            <el-table-column label="判定" fixed width="160px">
                                <template #default="scope">
                                <el-radio-group v-model="contestantExamReviewList[contestantExamReviewNextGradeIndex].contestant[scope.$index].examResult">
                                    <el-radio-button label=1>通过</el-radio-button>
                                    <el-radio-button label=2>不通过</el-radio-button>
                                </el-radio-group>
                                </template>
                            </el-table-column>

                            <el-table-column fixed prop="studentName" label="姓名" />
                            
                            <el-table-column label="考核项">
                                <el-table-column v-for="(scoreRatio, scoreRatioIndex) in contestantExamReviewList[contestantExamReviewNextGradeIndex].scoreRatio.ratioList" :key="scoreRatio.key" :label="scoreRatio.ratioTitle + '(' + scoreRatio.ratioScore + ')'">
                                    <el-table-column v-for="(ratioSubItem, ratioSubItemIndex) in scoreRatio.ratioSubItem" :key="ratioSubItem.key" :label="ratioSubItem.ratioTitle + '(' + ratioSubItem.ratioScore + ')'">
                                        <template #default="scope">
                                            <p>{{ contestantExamReviewList[contestantExamReviewNextGradeIndex].contestant[scope.$index].contestantScore[scoreRatioIndex].subScore[ratioSubItemIndex].score }}</p>
                                        </template>
                                    </el-table-column>
                                </el-table-column>
                            </el-table-column>

                            <el-table-column fixed="right" prop="scoreAll" label="总成绩" />
                        </el-table>
                     </div>
                </div>
            </div>
        </el-main>
    </el-container>

    <el-dialog v-model="qrCodeShow" title="报名二维码" width="500px" center>
        <div class="centerImage">
            <img :src="qrCodeImageUrl" style="width:300px; height: 300px;" />
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="qrCodeShow = false">关闭</el-button>
            </span>
        </template>
    </el-dialog>

    <el-dialog :title="processItem.title" v-model="processItem.isShow" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" center width="20%" :status="processItem.processNumber == 100">
    <div style="text-align: center;">
        <el-progress type="circle" :percentage="processItem.processNumber"></el-progress>
    </div>
    </el-dialog>
</template>

<script>
    import { ElLoading } from 'element-plus'

    import matchesModel from '@/models/matches'
    import fileImageModel from '@/models/fileImage'
    import fileXlsxModel from '@/models/fileXlsx'
    import interaction from '@/utils/interaction'
    import dataJudgment from '@/utils/dataJudgment'
    import contestantIndex from '@/view/matchesControl/contestantIndex'
    import xlsx from '@/utils/xlsx'

    export default {
        name: 'show',
        props: {
            msg: String
        },
        components: {
            contestantIndex,
        },
        data() {
            return {
                pageIndex: 1,

                matchesId: undefined,
                matches: undefined,

                //考核项信息，用于显示考核项
                matchesScoreRatioIndex: 0,
                matchesScoreRatio: [],
                //考核项合并列表
                matchesScoreRatioMergeList: [],

                //选手对应等级信息，用于成绩审核
                contestantExamReviewList: undefined,
                contestantExamReviewNextGradeIndex: 0,
                
                qrCodeShow: false,
                qrCodeImageUrl: undefined,

                xlsxFileList: [],
                xlsxData: undefined,
                xlsxGradeTextList: [],

                //批量添加选手时模式， 1-默认模式， 2-跆拳道模式，
                addContestantsMode: 2,

                processItem: {
                    title: "下载中，请稍后~",
                    isShow: false,
                    processNumber: 0,
                }
            }
        },
        methods: {
            initData() {

            },
            
            selectPage(e) {
                this.pageIndex = e;

                //切换页面组件数据处理
                switch(this.pageIndex) {
                    case 4:
                        this.$refs.contestantIndexRef.initData();
                    break;
                }
            },

            backView(errorTitle) {
                this.$router.push('/matchesControl/list');
                interaction.showErrorMessage(errorTitle);
            },

            //进度条函数
            refershAndStartProcess() {
                this.processItem.processNumber = 0;
                this.processItem.isShow = true;
            },

            //考级信息获取
            updateMatches(loading = undefined) {
                matchesModel.showMatches(
                    this.matchesId,
                    (res) => {
                        this.matchesDataProcessing(res);
                        if(loading != undefined) {
                            loading.close();
                        }
                    },
                    (res) => {
                        this.backView(res)
                        return;
                    }
                );
            },

            //考级信息处理
            matchesDataProcessing(res) {
                this.matches = res;

                this.xlsxGradeTextList = matchesModel.getGradeTextList(this.matches, "请选择");
                
                if(this.matches.contestant == undefined) {
                    this.matches.contestant = [];
                }

                if(this.matches.scoreRatio == undefined) {
                    this.matches.scoreRatio = [];
                }

                //排序
                this.matches.contestant = matchesModel.matchesContestantSortByNextGrade(this.matches.contestant);

                //用于修改选手段带使用
                for(var item of this.matches.contestant) {
                    item.newNextGrade = item.nextGrade;
                }

                //用于考核项显示
                let matchesScoreRatio = [];
                let defaultScoreRatio = {}
                for(var item of this.matches.scoreRatio) {
                    let scoreRatio = {};
                    let ratioList = [];

                    for(let ratioItem of item.ratioList) {
                        for(let ratioSubItem of ratioItem.ratioSubItem) {
                            ratioList.push({
                                ratioTitle: ratioItem.ratioTitle,
                                ratioScore: ratioItem.ratioScore,
                                ratioSubTitle: ratioSubItem.ratioTitle,
                                ratioSubScore: ratioSubItem.ratioScore,
                            })
                        }
                    }
                    scoreRatio.ratioList = ratioList;

                    scoreRatio.nextGrade = item.nextGrade;
                    if(item.isDefaultRatio) {
                        scoreRatio.nextGradeText = "默认选项";
                        defaultScoreRatio = scoreRatio;
                        continue;
                    }

                    scoreRatio.nextGradeText = matchesModel.getGradeText(this.matches, item.nextGrade);
                    matchesScoreRatio.push(scoreRatio);
                }
                matchesScoreRatio.sort(
                    (a, b) => {
                        return a.nextGrade - b.nextGrade;
                    }
                )
                matchesScoreRatio.push(defaultScoreRatio);
                this.matchesScoreRatio = matchesScoreRatio;

                //用于成绩审核显示
                this.contestantExamReviewList = [];
                let contestantByNextGrade = {};
                for(var item of this.matches.contestant) {
                    if(contestantByNextGrade[item.nextGrade] == undefined) {
                        contestantByNextGrade[item.nextGrade] = [];
                    }
                    contestantByNextGrade[item.nextGrade].push(item);
                }
                defaultScoreRatio = [];
                let scoreRatioByNextGrade = {}
                for(let item of this.matches.scoreRatio) {
                    if(item.isDefaultRatio) {
                        defaultScoreRatio = item;
                        continue;
                    }
                    scoreRatioByNextGrade[item.nextGrade] = item;
                }
                for(let key in contestantByNextGrade) {
                    this.contestantExamReviewList.push({
                        nextGrade: key,
                        nextGradeText: matchesModel.getGradeText(this.matches, key),
                        scoreRatio: scoreRatioByNextGrade[key] || defaultScoreRatio,
                        contestant: contestantByNextGrade[key],
                    })
                }

                for(var item of this.matches.contestant) {
                    item.imageUrl = fileImageModel.getStudentImagePath(item.studentId, item.studentName);
                }


                //以下操作会修改原始数据

                for(var contestantItem of this.matches.contestant) {
                    contestantItem.scoreAll = matchesModel.getMatchesContestantScoreText(
                        this.matches.scoreMode,
                        contestantItem.scoreAll
                    )

                    for(var contestantScoreItem of contestantItem.contestantScore) {
                        contestantScoreItem.score = matchesModel.getMatchesContestantScoreText(
                            this.matches.scoreMode,
                            contestantScoreItem.score
                        )
                        for(var contestantSubScoreItem of contestantScoreItem.subScore) {
                            contestantSubScoreItem.score = matchesModel.getMatchesContestantScoreText(
                                this.matches.scoreMode,
                                contestantSubScoreItem.score
                            )
                        }
                    }
                }

                // for(var item of this.matches.scoreRatio) {
                //     if(item.isDefaultRatio) {
                //         item.nextGrade = "默认选项";
                //         item.isDefaultRatio = "是";
                //     }else {
                //         item.nextGrade = matchesModel.getGradeText(this.matches, item.nextGrade);
                //         item.isDefaultRatio = "否";
                //     }
                // }

                this.matches.state = matchesModel.getMatchesState(this.matches.state);
                this.matches.time = dataJudgment.formatDate(this.matches.time);
                this.matches.scoreModeText = matchesModel.getMatchesScoreMode(this.matches.scoreMode);

                //刷新考核项显示
                this.matchesScoreRatioChange();
            },

            //----------考核项外观处理----------

            matchesScoreRatioChange() {
                this.matchesScoreRatioMerge();
            },

            matchesScoreRatioMerge() {
                let ratioList = this.matchesScoreRatio[this.matchesScoreRatioIndex].ratioList;
                let ratioMergeList = Array.from(Array(ratioList.length), () => ({
                    rowspan: 0,
                    colorMode: 0,
                }))
                let index = 0;
                let colorMode = 1;
                for(let i = 0 ; i < ratioList.length ; i ++) {
                    if(ratioList[i].ratioTitle != ratioList[index].ratioTitle) {
                        index = i;
                        colorMode = colorMode == 1? 2: 1;
                    }
                    ratioMergeList[index].rowspan += 1;
                    ratioMergeList[i].colorMode = colorMode;
                }

                this.matchesScoreRatioMergeList = ratioMergeList;
            },

            matchesScoreRatioSpanMethod(data) {
                let {rowIndex, columnIndex} = data;
                if (columnIndex === 0 || columnIndex === 3) {
                    return {
                        rowspan: this.matchesScoreRatioMergeList[rowIndex].rowspan,
                        colspan: this.matchesScoreRatioMergeList[rowIndex].rowspan > 0? 1: 0,
                    }
                }
            },

            ratioTableColor({row, rowIndex}) {
                return `color-mode-${this.matchesScoreRatioMergeList[rowIndex].colorMode}`
            },

            //----------考核项外观处理----------



            //----------功能按钮----------

            //修改状态
            updateState(state) {
                if(state == 0) {
                    matchesModel.changeMatchesEnrollState(
                        this.matchesId,
                        () => {
                            interaction.showSuccessMessage("修改成功!");
                            this.updateMatches();
                        },
                        (res) => {
                            interaction.showErrorMessage(res);
                        }
                    )
                }
                if(state == 1) {
                    matchesModel.changeMatchesWaitToStartState(
                        this.matchesId,
                        () => {
                            interaction.showSuccessMessage("修改成功!");
                            this.updateMatches();
                        },
                        (res) => {
                            interaction.showErrorMessage(res);
                        }
                    )
                }
            },
            //创建报名二维码
            createQRCode() {
                this.qrCodeImageUrl = fileImageModel.getMatchesQRCodeUrl(this.matchesId);
                this.qrCodeShow = true;
            },
            //删除考级
            deleteMatches() {
                matchesModel.deleteMatches(
                    this.matches.matchesId,
                    (res) => {
                        this.$router.push('/matchesControl/list');
                        interaction.showSuccessMessage("删除成功！");
                    },
                    (res) => {
                        interaction.showSuccessMessage("删除失败！" + res);
                    }
                );
            },

                //----------信息导出----------

            //下载选手成绩表
            downloadContestantIndexXlsx() {
                fileXlsxModel.getContestantIndexXlsx(
                    this.matches.matchesId,
                    this.matches.name,
                    (res) => {
                        interaction.showErrorMessage(res);
                    }
                );
            },
            //下载选手成绩表
            downloadContestantListXlsx() {
                fileXlsxModel.getContestantListXlsx(
                    this.matches.matchesId,
                    this.matches.name,
                    (res) => {
                        interaction.showErrorMessage(res);
                    }
                );
            },
            //下载选手基础信息表
            downloadContestantInformation() {
                fileXlsxModel.getContestantInformationXlsx(
                    this.matches.matchesId,
                    this.matches.name,
                    (res) => {
                        interaction.showErrorMessage(res);
                    }
                );
            },
            //下载选手照片
            downloadContestantImageByExamResult(examResult) {
                this.refershAndStartProcess();
                fileImageModel.downloadImagesFileByExamResult(
                    this.matches.matchesId,
                    this.matches.name,
                    examResult,
                    (res) => {
                        interaction.showErrorMessage(res);
                    },
                    this.processItem,
                );
            },
            //下载级位申办表
            downloadGradeApplicationXlsxByExamResult(examResult) {
                fileXlsxModel.getGradeApplicationXlsxByExamResult(
                    this.matches.matchesId,
                    this.matches.name,
                    examResult,
                    (res) => {
                        interaction.showErrorMessage(res);
                    }
                );
            },

                //----------信息导出----------

            //----------功能按钮----------



            //----------选手列表----------

            //修改选手等级
            changeMatchesContestantNextGrade(row) {
                var nextGrade = parseInt(this.matches.contestant[row].nextGrade);
                var newNextGrade = parseInt(this.matches.contestant[row].newNextGrade);

                if(nextGrade == newNextGrade) {
                    return;
                }

                if(isNaN(newNextGrade) || newNextGrade <= 0) {
                    interaction.showErrorMessage("段带错误，请选择正确段带！");
                    return;
                }

                const loading = ElLoading.service({ fullscreen: true })
                matchesModel.changeMatchesContestantNextGrade(
                    this.matches.matchesId, this.matches.contestant[row].studentId, nextGrade, newNextGrade,
                    () => {
                        interaction.showSuccessMessage("修改成功！");
                        this.updateMatches(loading);
                    },
                    (res) => {
                        interaction.showErrorMessage(res);
                        this.updateMatches(loading);
                    }
                )
            },
            //修改选手照片
            changeContestantImage(e, index) {
                var file = e.file;
                var contestant = this.matches.contestant[index];
                if (!file) {
                    return false
                }
                if (!/\.(jpg|png)$/.test(file.name.toLowerCase())) {
                    interaction.showErrorMessage("请上传JPG/PNG文件！");
                    return false
                }

                fileImageModel.uploadStudentImage(
                    file, contestant.studentId, contestant.studentName,
                    () => {
                        interaction.showSuccessMessage("图片上传成功");
                        contestant.imageUrl = fileImageModel.getStudentImagePathNeedRefersh(contestant.studentId, contestant.studentName);
                    },
                    (res) => {
                        interaction.showErrorMessage(res);
                    }
                );
            },
            //删除选手

            deleteMatchesContestant(row) {
                const loading = ElLoading.service({ fullscreen: true })
                matchesModel.deleteMatchesContestant(
                    this.matches.matchesId, this.matches.contestant[row].studentId, this.matches.contestant[row].nextGrade,
                    () => {
                        interaction.showSuccessMessage("删除成功！");
                        this.updateMatches(loading);
                    },
                    (res) => {
                        interaction.showErrorMessage(res);
                        loading.close();
                    }
                );
            },

            //----------选手列表----------
            
            

            //----------选手添加----------

            clearXlsxData() {
                this.xlsxData = undefined;
                this.xlsxFileList = [];
                this.addContestantsShow = false;
            },

            downloadXlsx() {
                xlsx.contestantUploadExampleXlsx();
            },

            xlsxFileChange(file, fileList) {
                this.xlsxFileList = fileList.slice(0, 1);
            },

            xlsxFileRequest(e) {
                xlsx.readContestantUploadXlsx(e.file, (xlsxData) => {
                    if(xlsxData == undefined || xlsxData.length <= 0) {
                        interaction.showErrorMessage("该表为空表，请检查数据");
                        return;
                    }

                    var dataList = [];
                    for(var item of xlsxData) {
                        dataList.push({
                            studentId: item["身份证"],
                            studentName: item["姓名"],
                            imageUrl: fileImageModel.getStudentImagePath(item["身份证"], item["姓名"]),
                            imageLoadError: false,
                            nextGrade: item["考核等级"],
                            nextGradeText: matchesModel.getGradeText(this.matches, item["考核等级"]),
                        })
                    }
                    
                    for(var item of dataList) {
                        if(isNaN(item.nextGrade)) {
                            interaction.showErrorMessage("考核等级处只能填写数字！");
                            return;
                        }
                    }

                    //根据模式修改等级显示
                    switch(this.addContestantsMode) {
                    case 1:
                        break;
                    case 2:
                        for(var item of dataList) {
                            let max = 11;
                            item.nextGrade = max - item.nextGrade;
                            item.nextGradeText = matchesModel.getGradeText(this.matches, item.nextGrade);
                        }
                        break;
                    default:
                    }

                    this.xlsxData = dataList;
                });
            },

            xlsxImageFileRequest(e, row) {
                var file = e.file;
                if (!file) {
                    return false
                }
                if (!/\.(jpg|png)$/.test(file.name.toLowerCase())) {
                    interaction.showErrorMessage("请上传JPG/PNG文件！");
                    return false
                }

                fileImageModel.uploadStudentImage(
                    file, this.xlsxData[row].studentId, this.xlsxData[row].studentName,
                    () => {
                        interaction.showSuccessMessage("图片上传成功");
                        this.xlsxData[row].imageLoadError = false;
                        this.xlsxData[row].imageUrl = fileImageModel.getStudentImagePath(this.xlsxData[row].studentId, this.xlsxData[row].studentName);
                    },
                    (res) => {
                        interaction.showErrorMessage(res);
                    }
                );
            },

            xlsxDataImageLoadError(row) {
                this.xlsxData[row].imageLoadError = true;
                this.xlsxData[row].imageUrl = "";
            },

            deleteCurrentXlsxData(row) {
                if(row < 0 || row >= this.xlsxData.length) { 
                    return;
                }
                
                this.xlsxData.splice(row,1);
            },

            saveContestants() {
                if(this.xlsxData == undefined || this.xlsxData.length <= 0) {
                    interaction.showErrorMessage("当前无导入选手数据，请查看Excel文件后重试！");
                    return;
                }

                for(var item of this.xlsxData) {
                    if(item.imageLoadError) {
                        interaction.showErrorMessage("有选手的照片未上传，请上传后重试！");
                        return;
                    }
                    if(item.nextGrade <= 0) {
                        interaction.showErrorMessage("有选手段带未选择，请选择段带！");
                        return;
                    }
                }

                var contestants = [];
                for(var item of this.xlsxData) {
                    contestants.push({
                        studentId: item.studentId,
                        studentName: item.studentName,
                        nextGrade: parseInt(item.nextGrade),
                    });
                }

                matchesModel.addMatchesContestants(
                    this.matchesId, JSON.stringify(contestants),
                    () => {
                        interaction.showSuccessMessage("添加成功！");
                        this.clearXlsxData();
                        this.updateMatches();
                    },
                    (res) => {
                        interaction.showErrorMessage(res);
                    }
                );
            },

            //----------选手添加----------



            //----------成绩审核----------

            changeExamResult(examResult) {
                examResult = parseInt(examResult);
                for(var i = 0 ; i < this.matches.contestant.length ; i ++) {
                    this.matches.contestant[i].examResult = examResult;
                }
            },
            saveExamResult() {
                var contestant = [];
                for(var i = 0 ; i < this.matches.contestant.length ; i ++) {
                    contestant.push({
                        studentId: this.matches.contestant[i].studentId,
                        nextGrade: this.matches.contestant[i].nextGrade,
                        examResult: this.matches.contestant[i].examResult,
                    })
                }

                const loading = ElLoading.service({ fullscreen: true })
                matchesModel.saveMatchesExamResult(
                    this.matches.matchesId,
                    JSON.stringify(contestant),
                    () => {
                        interaction.showSuccessMessage("成绩确认成功！");
                        this.examDetermineShow = false;
                        this.updateMatches(loading);
                    },
                    (res) => {
                        loading.close();
                        interaction.showErrorMessage(res);
                    }
                );
            },

            //----------成绩审核----------
        },
        mounted() {
            this.matchesId = this.$route.params.matchesId || undefined;
            if(this.matchesId == undefined) {
                this.backView("请选择考级")
                return;
            }

            this.initData();

            //获取赛事
            this.updateMatches();
        }
    }
</script>

<style src="@/css/view.css" scoped></style>
<style src="@/css/information.css" scoped></style>
<style scoped>
    .centerImage {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .main {
        margin-left: 5%;
        margin-right: 5%;
    }
    .cardPadding {
        padding: 20px;
    }
    .cardItemByInformation {
        display: flex; 
        flex-flow: row || wrap;
        justify-content: space-around; 
        align-items: center; 
        font-size: 16px;
    }
    .cardItemByButton {
        display: flex; 
        flex-flow: row || wrap; 
        justify-content: center; 
        align-items: center;
    }
</style>
<style>
    .el-table .color-mode-1 {
        background: white;
    }
    .el-table .color-mode-2 {
        --el-table-tr-bg-color: var(--el-color-warning-light-9);
    } 
</style>
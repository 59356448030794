<template>
    <HeaderView></HeaderView>
    <router-view></router-view>
</template>

<script>
    import HeaderView from "@/components/Header.vue"

    export default {
        name: 'index',
        props: {
            msg: String
        },
        components: {
            HeaderView,
        },
    }
</script>

<style scoped>
</style>
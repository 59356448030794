<template>
    <div class="parentView">
        <div class="title">
            <h1>考核项模板管理</h1>
        </div>
        
        <el-table :data="matchesRatioList" stripe style="width: 100%;" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}">
            <el-table-column prop="name" label="考核项模板名称" />
            <el-table-column label="详情">
                <template #default="scope">
                    <el-button type="primary" size="large" @click="showMatchesRatio(scope.$index)">查看详情</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import interaction from '@/utils/interaction';
    import matches_ratio from '@/models/matches_ratio';

    export default {
        name: 'list',
        props: {
            msg: String
        },
        data() {
            return {
                matchesRatioList: undefined,
            }
        },
        methods: {
            initData() {
                matches_ratio.selectMatchesRatioByUserId(
                    (res) => {
                        this.matchesRatioList = res;
                    },
                    (res) => {
                        interaction.showErrorMessage(res);
                    }
                );
            },

            showMatchesRatio(index) {
                this.$router.push({
                    name: 'matchesRatioShow',
                    params: {
                        ratioId: this.matchesRatioList[index].ratioId
                    }
                })
            },
        },
        mounted() {
            this.initData();
        }
    }
</script>

<style src="@/css/view.css" scoped></style>
<style src="@/css/information.css" scoped></style>
<style scoped>

</style>
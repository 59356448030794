<template>
    <div class="card-background">
        <p class="card-title">{{ isWeChatQRCodeMode? "微信登录": "账号登录" }}</p>
        <div class="card">
            <div class="card" v-if="!isWeChatQRCodeMode">
                <el-input style="width: 400px" class="card-item" v-model="phone" oninput="value=value.replace(/[^\w]/g,'')" maxlength="18" size="large" placeholder="输入手机号/账号" clearable>
                    <template #append>
                        <el-button @click="isCodeMode = !isCodeMode">{{ isCodeMode? "输入密码": "输入验证码" }}</el-button>
                    </template>
                </el-input>
                <div v-if="isCodeMode">
                    <el-input style="width: 400px" class="card-item" v-model="code" maxlength="6" oninput="value=value.replace(/[^\d]/g,'')" size="large" placeholder="输入验证码" clearable>
                        <template #append>
                            <el-button @click="getPhoneCode()">获取验证码</el-button>
                        </template>
                    </el-input>
                </div>
                <div v-else>
                    <el-input style="width: 400px" class="card-item" v-model="password" maxlength="16" type="password" size="large" placeholder="输入密码" clearable />
                </div>
                <el-button class="card-item" size="large" type="primary" @click="userLoginByCode()" style="width: 40%">登录</el-button>
            </div>
            <div :hidden="!isWeChatQRCodeMode" id="loginQRCode"></div>
        </div>
        <el-button class="card-item" size="large" type="primary" @click="isWeChatQRCodeMode = !isWeChatQRCodeMode">{{ isWeChatQRCodeMode? "账号密码": "微信登录" }}</el-button>
    </div>
</template>

<script>
    import user from "@/models/user"
    import interaction from "@/utils/interaction"

    export default {
        name: 'userLogin',
        props: {
            msg: String,
        },
        data() {
            return {
                isCodeMode: true,
                isWeChatQRCodeMode: true,
                phone: "",
                code: "",
                password: "",
            }
        },
        methods: {
            getPhoneCode() {
                if(this.phone == "") {
                    interaction.showErrorMessage("手机号为空！")
                    return
                }

                if(this.phone.length != 11) {
                    interaction.showErrorMessage("手机号长度错误！")
                    return
                }

                user.getPhoneCode(
                    this.phone,
                    (res) => {
                        interaction.showSuccessMessage("已发送，请注意查收")
                    },
                    (res) => {
                        interaction.showErrorMessage(res)
                    }
                )
            },
            userLoginByCode() {
                if(this.phone == "") {
                    interaction.showErrorMessage("手机号为空！")
                    return
                }

                // if(this.phone.length != 11) {
                //     interaction.showErrorMessage("手机号长度错误！")
                //     return
                // }

                if(this.isCodeMode) {
                    if(this.code == "") {
                        interaction.showErrorMessage("验证码为空！")
                        return
                    }

                    if(this.code.length != 6) {
                        interaction.showErrorMessage("验证码长度错误！")
                        return
                    }

                    var that = this;
                    user.userLoginByCode(
                        this.phone, this.code, 
                        (res) => {
                            this.$router.push("/");
                            interaction.showSuccessMessage("登录成功！");
                            
                        },
                        (res) => {
                            interaction.showErrorMessage(res);
                        }
                    )
                }
                else {
                    if(this.password == "") {
                        interaction.showErrorMessage("密码为空！")
                        return
                    }

                    user.userLoginByPassword(
                        this.phone, this.password, 
                        (res) => {
                            this.$router.push("/");
                            interaction.showSuccessMessage("登录成功！");
                        },
                        (res) => {
                            interaction.showErrorMessage(res);
                        }
                    )
                }
            },
            initWxLoginQRCode() {
                var obj = new WxLogin({
                    self_redirect:false,
                    id:"loginQRCode", 
                    appid: "wx676506d837d58f1d", 
                    scope: "snsapi_login",
                    redirect_uri: encodeURIComponent("https://edu.xihetiyu.com/#/auth/wechatLoginCheck"),
                    // redirect_uri: "https%3A%2F%2Fedu.xihetiyu.com%2F%23%2Fauth%2FwechatLoginCheck", 
                    state: Math.ceil(Math.random()*1000), 
                    style: "black",
                    href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4OyBtYXJnaW46IGF1dG87fQ0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30NCi5pbXBvd2VyQm94IC5pbmZvIHtkaXNwbGF5OiBub25lO30NCi5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0NCi5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9IA=="
                });
            }
        },
        mounted() {
            document.querySelector("body").setAttribute("style", "background: linear-gradient(to right, rgb(255, 239, 186), rgb(255, 255, 255))");
            this.initWxLoginQRCode();
        },
        beforeRouteLeave() {
            document.querySelector("body").removeAttribute("style");
        },
        
    }
</script>

<style scoped>

body {
    background: linear-gradient(to right, rgb(255, 239, 186), rgb(255, 255, 255));
}

.card-background {
    width: 550px;
    height: 450px;

    background-color: white;

    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);

    box-shadow: 0 5px 5px rgba(0,0,0,.2),0 10px 15px rgba(63, 63, 63, 0.3);
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card {
    height: 220px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-title {
    font-size: 25px;
    font-weight: bold;
}

.card-item {
    margin-bottom: 10px;
}
</style>
  


function getSessionStorage(key) {
    return sessionStorage.getItem(key);
}

function setSessionStorage(key, value) {
    sessionStorage.setItem(key, value);
}

function getLocalStorage(key) {
    return localStorage.getItem(key);
}

function setLocalStorage(key, value) {
    localStorage.setItem(key, value);
}



function setUserToken(userToken) {
    setLocalStorage("userToken", userToken);
}

function getUserToken() {
    return getLocalStorage("userToken");
}

function setLevelExamMatchesData(matchesData) {
    setLocalStorage("matchesData", matchesData);
}

function getLevelExamMatchesData() {
    return getLocalStorage("matchesData");
}

export default {
    setUserToken,
    getUserToken,
    setLevelExamMatchesData,
    getLevelExamMatchesData,
}
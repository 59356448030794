
import http from "@/utils/http"
import SystemPara from "@/data/SystemPara"
import interaction from "@/utils/interaction"
import router from "@/router/index"
import errorCode from "./errorCode"

var rootUrl = "/User/"

function getPhoneCode(phone, success, fail) {
    http.postServer(
        rootUrl + "getPhoneCode",
        {
            phone: phone,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function userLoginByCode(phone, code, success, fail) {
    http.postServer(
        rootUrl + "userLoginByPhoneCode",
        {
            phone: phone,
            code: code,
            role: 1,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code));
                return
            }
            SystemPara.setUserToken(result.userToken);
            success();
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    );
}

function userLoginByPassword(account, password, success, fail) {
    http.postServer(
        rootUrl + "userLoginByPassword",
        {
            account: account,
            password: password,
            role: 1,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code));
                return
            }
            SystemPara.setUserToken(result.userToken);
            success();
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    );
}

function userLoginByWeChatQRCode(code, success, fail) {
    http.postServer(
        rootUrl + "userLoginByWeChatQRCode",
        {
            code: code,
            role: 1,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code));
                return
            }
            SystemPara.setUserToken(result.userToken);
            success();
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    );
}

function userRegistry(account, password, phone, code, success, fail) {
    http.postServer(
        rootUrl + "userRegistry",
        {
            account: account,
            password: password,
            phone: phone,
            wechatId: "",
            code: code,
            role: 1,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function userIsLogin(success = () => {}, fail = () => {}) {

    var failFunction = (res) => {
        interaction.showErrorMessage("请先登录")
        router.push('/user/userLogin')
        fail();
    }

    var userToken = SystemPara.getUserToken() || "";

    http.postServer(
        rootUrl + "userIsLogin",
        {
            userToken: userToken
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                failFunction(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            failFunction(errorCode.getErrorCodeByText())
        }
    )
}


export default {
    getPhoneCode,
    userLoginByCode,
    userLoginByPassword,
    userLoginByWeChatQRCode,
    userRegistry,
    userIsLogin,
};

import matchesModel from '@/models/matches'
import fileImage from '@/models/fileImage'
import interaction from '@/utils/interaction';
import localData from '@/utils/localData';


//考级原始数据存储
function getMatchesData() {
    return JSON.parse(localData.getLevelExamMatchesData());
}

function setMatchesData(matchesData) {
    localData.setLevelExamMatchesData(JSON.stringify(matchesData));
}


/**
 * 考级段带排序-由小到大
 * @param {*} matches 
 */
function changeMatches(matches) {
    matches.contestant = matchesModel.matchesContestantSortByBatchAndIndex(matches.contestant);

    for(var i = 0 ; i < matches.contestant.length ; i ++) {
        matches.contestant[i].isComplete = false;
        matches.contestant[i].isWaiver = false;
        matches.contestant[i].image = fileImage.getStudentImagePath(matches.contestant[i].studentId, matches.contestant[i].studentName);
    }

    setMatchesData(matches);
}

//通过段带获取考核项标题和分值
function getMatchesRatio(grade) {
    var matchesData = getMatchesData();

    for(var ratioItem of matchesData.scoreRatio) {
        if(ratioItem.nextGrade == grade) {
            return ratioItem;
        }
    }

    
    return [];
}

function saveContestantExamResult(contestant, examinerIndex) {
    //删除非一键操作行
    var newContestant = contestant.slice(0, contestant.length - 1);

    var matchesData = getMatchesData();

    matchesModel.changeMatchesExam(
        matchesData.matchesId, newContestant, examinerIndex,
        () => {
            interaction.showSuccessMessage("成绩已上传");
        },
        (res) => {
            interaction.showErrorMessage(res);
        }
    )

    for(var i = 0 ; i < newContestant.length ; i ++) {
        for(var j = 0 ; j < matchesData.contestant.length ; j ++) {
            if(newContestant[i].studentId == matchesData.contestant[j].studentId && newContestant[i].nextGrade == matchesData.contestant[j].nextGrade) {
                matchesData.contestant[j].contestantScore = newContestant[i].contestantScore;
                matchesData.contestant[j].isComplete = true;
                break;
            }
        }
    }

    setMatchesData(matchesData);
}

//查询下一批选手-默认情况
function selectNextContestantByDefault(count = 5) {
    var contestant = getMatchesData().contestant;

    var selectGrade = undefined;
    var selectContestant = [];

    for(var i = 0 ; i < contestant.length ; i ++) {
        if(!contestant[i].isComplete) {
            selectGrade = contestant[i].nextGrade;
            for(var j = i, selectCount = 0 ; j < contestant.length ; j ++, selectCount ++) {
                if(selectCount >= count)
                    break;
                if(selectGrade != contestant[j].nextGrade)
                    continue;
                if(!contestant[j].isComplete) {
                    selectContestant.push(contestant[j]);
                }
                    
            }
            break;
        }
    }

    return selectContestant;
}

/**
 * 查询下一批选手-选择选手
 * @param {*} studentList 选手元素-所有
 * @returns 
 */
function selectNextContestantByList(studentList) {
    var contestant = getMatchesData().contestant;

    var selectContestant = [];

    for(var i = 0 ; i < studentList.length ; i ++) {
        for(var j = 0 ; j < contestant.length ; j ++) {
            if(studentList[i].studentId == contestant[j].studentId && studentList[i].nextGrade == contestant[j].nextGrade) {
                selectContestant.push(contestant[j]);
                break;
            }
        }
    }

    return selectContestant;
}

/**
 * 获取考核项-根据段带
 * @param {*} grade 
 * @returns 
 */
function getScoreRatioByNextGrade(grade) {
    var scoreRatio = getMatchesData().scoreRatio;

    var defaultRatio = undefined;
    for(var scoreRatioItem of scoreRatio) {
        if(scoreRatioItem.nextGrade == grade) {
            return scoreRatioItem;
        }
        if(scoreRatioItem.isDefaultRatio) {
            defaultRatio = scoreRatioItem;
        }
    }
    return defaultRatio;
}

/**
 * 获取考核项名称列表-根据ScoreRatio
 * @param {*} grade 
 */
function getScoreRatioTextListByScoreRatio(scoreRatio) {
    var list = [];
    for(var item of scoreRatio.ratioList) {
        list.push(item.ratioTitle);
    }
    return list;
}

function clearScoreAll() {
    var matches = getMatchesData();

    for(var contestantItem of matches.contestant) {
        contestantItem.isComplete = false;

        for(var contestantScoreItem of contestantItem.contestantScore) {
            contestantScoreItem.score = 0;
            for(var contestantSubScoreItem of contestantScoreItem.subScore) {
                contestantSubScoreItem.score = 0;
            }
        }
    }

    setMatchesData(matches);
}

//获取通过/不通过对应百分比
function getPassOrFailPercent(number) {
    number = parseInt(number);
    switch(number) {
    case 1:
        return 0.75;
    case 2:
        return 0.25;
    default:
        return 0;
    }
}

//通过百分比获取通过/不通过
function getPassOrFailByPercent(number) {
    if(number >= 0.6) {
        return 1;
    }
    else {
        return 2;
    }
}

//获取ABCD对应百分比
function getWordButtonPercent(number) {
    number = parseInt(number);
    switch(number) {
    case 4:
        return 1;
    case 7:
        return 0.75;
    case 10:
        return 0.5;
    case 11:
        return 0.25;
    default:
        return 0;
    }
}

//通过百分比获取ABCD
function getWordButtonByPercent(number) {
    var wordStartNumber = 3;
    if(number >= 0.95) {
        return wordStartNumber;
    }
    else if(number >= 0.9) {
        return wordStartNumber + 1;
    }
    else if(number >= 0.85) {
        return wordStartNumber + 2;
    }
    else if(number >= 0.8) {
        return wordStartNumber + 3;
    }
    else if(number >= 0.75) {
        return wordStartNumber + 4;
    }
    else if(number >= 0.7) {
        return wordStartNumber + 5;
    }
    else if(number >= 0.65) {
        return wordStartNumber + 6;
    }
    else if(number >= 0.6) {
        return wordStartNumber + 7;
    }
    else {
        return wordStartNumber + 8;
    }
}

 export default {
    getMatchesData,
    changeMatches,
    getMatchesRatio,
    saveContestantExamResult,
    selectNextContestantByDefault,
    selectNextContestantByList,
    getScoreRatioByNextGrade,
    getScoreRatioTextListByScoreRatio,
    clearScoreAll,

    getPassOrFailPercent,
    getPassOrFailByPercent,
    getWordButtonPercent,
    getWordButtonByPercent,
};
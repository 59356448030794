<template>
    <div class="card">
        <h1>用户注册</h1>
        
        <el-input class="card-item" v-model="phone" oninput="value=value.replace(/[^\d]/g,'')" maxlength="11" size="large" placeholder="输入手机号" clearable />
        <el-input class="card-item" v-model="code" maxlength="6" oninput="value=value.replace(/[^\d]/g,'')" size="large" placeholder="输入验证码" clearable>
            <template #append><el-button @click="getPhoneCode()">获取验证码</el-button></template>
        </el-input>
        <el-input class="card-item" v-model="account" maxlength="16" type="text" size="large" placeholder="输入账户" clearable />
        <el-input class="card-item" v-model="password" maxlength="16" type="password" size="large" placeholder="输入密码" clearable />
        <el-input class="card-item" v-model="passwordAgain" maxlength="16" type="password" size="large" placeholder="再次输入密码" clearable />
        <p>账户可以为空，若账号密码均有则可以用账号密码登录</p>
        <p>密码可以为空，不输入密码则默认仅验证码登录</p>
        <el-button class="card-item" size="large" type="primary" @click="userRegistry()" style="width: 60%">注册</el-button>
    </div>
</template>

<script>
    import user from "@/models/user"
    import interaction from "@/utils/interaction"

    export default {
        name: 'userRegistry',
        props: {
            msg: String,
        },
        data() {
            return {
                phone: "",
                code: "",
                account: "",
                password: "",
                passwordAgain: "",
            }
        },
        methods: {
            getPhoneCode() {
                if(this.phone == "") {
                    interaction.showErrorMessage("手机号为空！")
                    return;
                }

                if(this.phone.length != 11) {
                    interaction.showErrorMessage("手机号长度错误！")
                    return;
                }

                user.getPhoneCode(
                    this.phone,
                    (res) => {
                        interaction.showSuccessMessage("已发送，请注意查收")
                    },
                    (res) => {
                        interaction.showErrorMessage(res)
                    }
                )

                
            },

            userRegistry() {
                if(this.phone == "") {
                    interaction.showErrorMessage("手机号为空！")
                    return;
                }

                if(this.phone.length != 11) {
                    interaction.showErrorMessage("手机号长度错误！")
                    return;
                }

                if(this.code == "") {
                    interaction.showErrorMessage("验证码为空！")
                    return;
                }

                if(this.code.length != 6) {
                    interaction.showErrorMessage("验证码长度错误！")
                    return;
                }

                if(this.password != this.passwordAgain) {
                    interaction.showErrorMessage("两次密码不一致！")
                    return;
                }

                user.userRegistry(
                    this.account, this.password, this.phone, this.code, 
                    (res) => {
                        interaction.showSuccessMessage("注册成功！")
                        this.$router.push('/user/userLogin')
                    },
                    (res) => {
                        interaction.showErrorMessage(res)
                    }
                )
            },
        }
    }
</script>

<style scoped>
.box-card {
    width: 500px;
}
.card {
    width: 400px;
    position: absolute;
    top: 30%;
    left: 0%;
    right: 0%;
    margin: auto;

    justify-content: center;
    align-items: center;
    text-align: center;
}

.card-item {
    margin-bottom: 10px;
}
</style>
  

import { ElMessage } from 'element-plus'

function showErrorMessage(message) {
    ElMessage.error(message)
}

function showSuccessMessage(message) {
    ElMessage.success(message)
}

export default {
    showErrorMessage,
    showSuccessMessage
}
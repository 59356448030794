<template>
    <div class="parentView">
        <div class="title">
            <h1>考核项模板创建</h1>
        </div>

        <div class="centerList">
            <el-select v-model="matchesRatioSelectByCopy" placeholder="请选择考核模板">
                <el-option v-for="(item) in matchesRatioList" :key="item.key" :label="item.name" :value="item.ratioId"></el-option>
            </el-select>
            <el-button type="primary" @click="copyMatchesRatio()">导入已创建的考核模板</el-button>
        </div>

        <el-divider />

        <el-form v-if="formData != undefined" :model="formData" :label-position="labelPosition" label-width="100px">
            <el-form-item label="考核项模板名称：">
                <el-input v-model="formData.name" />
            </el-form-item>

            <el-divider />

            <el-form-item label="考核项：">
                <div class="fullWidth" v-for="(item, index) in formData.ratioList" :key="item">
                    <div class="spaceBetweenList marginBottom">
                        <div class="ratioItemList">
                            <p class="ratioItemTitle">考核项名称：</p>
                            <el-input class="ratioItemInput" v-model="item.ratioTitle" />
                        </div>
                        <div class="ratioItemList">
                            <p>考核子项分数总和：</p>
                            <p>{{ item.ratioScore }}</p>
                        </div>
                    </div>
                    
                    <el-table :data="item.ratioSubItem" stripe style="width: 100%;" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}">
                        <el-table-column label="考核子项名称" min-width="150px">
                            <template #default="scope">
                                <el-input v-model="formData.ratioList[index].ratioSubItem[scope.$index].ratioTitle" />
                            </template>
                        </el-table-column>
                        <el-table-column label="考核子项分数" min-width="150px">
                            <template #default="scope">
                                <el-input-number :precision="0" v-model="formData.ratioList[index].ratioSubItem[scope.$index].ratioScore" @change="changeRatioScore(index)" />
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="centerList">
                        <el-button type="primary" @click="addRatioSubItem(index)">添加考核子项</el-button>
                        <el-button type="primary" @click="popRatioSubItem(index)">删除最后一个考核子项</el-button>
                    </div>

                    <el-divider />
                </div>
            </el-form-item>
        </el-form>

        <el-divider />

        <div class="centerList">
            <el-button type="primary" @click="addRatioItem()">添加考核项</el-button>
            <el-button type="primary" @click="popRatioItem()">删除最后一个考核项</el-button>
        </div>

        <div class="centerList">
            <el-button type="primary" @click="createMatchesRatio()">创建考核模板</el-button>
        </div>

        <div class="bottomSpace"></div>
    </div>
</template>

<script>
    import interaction from '@/utils/interaction';
    import dataJudgment from '@/utils/dataJudgment';
    import matches_ratio from '@/models/matches_ratio';

    export default {
        name: 'create',
        props: {
            msg: String
        },
        data() {
            return {
                formData: undefined,
                matchesRatioList: undefined,
                matchesRatioSelectByCopy: undefined,
            }
        },
        methods: {
            initData() {
                this.formData = {
                    name: "",
                    ratioList: [],
                }
                this.addRatioItem();

                matches_ratio.selectMatchesRatioByUserId(
                    (res) => {
                        this.matchesRatioList = res;
                    },
                    (res) => {
                        interaction.showErrorMessage(res);
                    }
                );
            },

            addRatioItem() {
                this.formData.ratioList.push(
                    {
                        ratioScore: 0,
                        ratioTitle: "",
                        ratioSubItem: [],
                    }
                )
                this.addRatioSubItem(this.formData.ratioList.length - 1);
            },
            popRatioItem() {
                if(this.formData.ratioList.length > 1) {
                    this.formData.ratioList.pop();
                }
            },

            addRatioSubItem(index) {
                if(index >= this.formData.ratioList.length) {
                    return;
                }

                this.formData.ratioList[index].ratioSubItem.push(
                    {
                        ratioScore: 0,
                        ratioTitle: "",
                    }
                )
            },
            popRatioSubItem(index) {
                if(index >= this.formData.ratioList.length) {
                    return;
                }

                if(this.formData.ratioList[index].ratioSubItem.length > 1) {
                    this.formData.ratioList[index].ratioSubItem.pop();
                }
            },

            changeRatioScore(index) {
                var score = 0;
                for(var item of this.formData.ratioList[index].ratioSubItem) {
                    score += item.ratioScore;
                }
                this.formData.ratioList[index].ratioScore = score;
            },

            createMatchesRatio() {
                if(!dataJudgment.dataIsValue(this.formData.name)) {
                    interaction.showErrorMessage("考核项模板名称不能为空！");
                    return;
                }

                for(var ratioItem of this.formData.ratioList) {
                    if(!dataJudgment.dataIsValue(ratioItem.ratioTitle)) {
                        interaction.showErrorMessage("考核项名称不能为空！");
                        return;
                    }
                    for(var ratioSubItem of ratioItem.ratioSubItem) {
                        if(!dataJudgment.dataIsValue(ratioSubItem.ratioTitle)) {
                            interaction.showErrorMessage("考核子项名称不能为空！");
                            return;
                        }
                        if(ratioSubItem.ratioScore <= 0) {
                            interaction.showErrorMessage("考核子项分数必须大于0！");
                            return;
                        }
                    }
                }

                matches_ratio.createMatchesRatio(
                    this.formData.name, JSON.stringify(this.formData.ratioList),
                    (res) => {
                        interaction.showSuccessMessage("创建成功！");
                        this.$router.push("/matchesRatio/list");
                    },
                    (res) => {
                        interaction.showErrorMessage(res);
                    }
                );
            },

            copyMatchesRatio() {
                if(this.matchesRatioSelectByCopy == undefined) {
                    interaction.showErrorMessage("请选择考核模板后重试！")
                    return;
                }

                matches_ratio.selectMatchesRatioByRatioId(
                    this.matchesRatioSelectByCopy,
                    (res) => {
                        this.formData.name = res.name + "-复制";
                        this.formData.ratioList = res.ratioList;
                    },
                    (res) => {
                        interaction.showErrorMessage(res);
                    }
                );
            },
        },
        mounted() {
            this.initData();
        }
    }
</script>

<style src="@/css/view.css" scoped></style>
<style src="@/css/information.css" scoped></style>
<style scoped>
    .ratioItemList {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        width: 100%;
    }

    .ratioItemInput {
        width: 50%;
    }

</style>
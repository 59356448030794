<template>
  <div></div>
</template>

<script>
    import user from "@/models/user"
    import interaction from "@/utils/interaction"
    export default {
        name: 'wechatLoginCheck',
        props: {
            msg: String,
        },
        data() {
            return {
            }
        },
        methods: {

        },
        mounted() {
            let code = this.$route.query.code;
            let state = this.$route.query.state;

            user.userLoginByWeChatQRCode(
                code,
                (res) => {
                    interaction.showSuccessMessage("登录成功！");
                    this.$router.push("/");
                },
                (res) => {
                    interaction.showErrorMessage("微信登录失效，请重新登录");
                    this.$router.push("/user/userLogin");
                }
            );
        }
    }
</script>

<style>

</style>